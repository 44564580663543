import React from 'react';
import classNames from 'classnames/bind';

import { useNavDrawerContext } from 'contexts/navDrawer';

import styles from './styles/AppHamburger.module.scss';

const cx = classNames.bind(styles);

interface Props {
  toggleLight?: boolean;
}

const AppHamburger: React.FC<Props> = ({ toggleLight }) => {
  const { isNavOpen, setIsNavOpen } = useNavDrawerContext();

  const barClassNames = cx({
    hamburger__bar: true,
    'hamburger__bar--light': toggleLight,
    'hamburger__bar--open': isNavOpen,
  });

  return (
    <div
      className={styles['hamburger']}
      role="button"
      data-event="click_toggle_hamburger_menu"
      onClick={() => {
        isNavOpen
          ? document.body.classList.remove('has-overlay')
          : document.body.classList.add('has-overlay');
        setIsNavOpen(!isNavOpen);
      }}
    >
      <span className={`${barClassNames}`}></span>
    </div>
  );
};

export default AppHamburger;
