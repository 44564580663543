import React from 'react';

import { useUser } from 'contexts/currentUser';

import { useRelativeAuthLinks } from 'hooks/useAuthRedirect';

import AppButtonLink from 'components/AppButton/AppButtonLink';

import styles from './styles/AppHeader.module.scss';

export interface NavLoginLogoutProps {
  className?: string;
}

const NavLoginLogout: React.FC<NavLoginLogoutProps> = ({
  className,
}: NavLoginLogoutProps) => {
  const currentUserContext = useUser();
  const { signInLink, signUpLink } = useRelativeAuthLinks();

  if (!currentUserContext) return null;

  if (!currentUserContext.user) {
    return (
      <div className={`${styles['drawer-login']} ${className}`}>
        <AppButtonLink
          className={`${styles['drawer-login__button']} ${styles['drawer-login__button--is-sign-up']}`}
          path={signUpLink}
          label="Join"
          dataEvent="click_nav_join"
          skipInterstitalAd
        />
        <AppButtonLink
          className={`${styles['drawer-login__button']} ${styles['drawer-login__button--is-sign-in']}`}
          path={signInLink}
          label="Sign In"
          ghost="action"
          dataEvent="click_nav_sign_in"
          skipInterstitalAd
        />
      </div>
    );
  }

  return null;
};

export default NavLoginLogout;
