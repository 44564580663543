import { useRef, useEffect } from 'react';

// The onClickOutside arg should be a memoized callback.
const useOnClickOutsideRef = (onClickOutside?: () => void) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        (e.target instanceof HTMLElement || e.target instanceof SVGElement) &&
        divRef.current &&
        divRef.current?.contains(e.target)
      ) {
        return;
      }
      onClickOutside?.();
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, divRef]);

  return divRef;
};

export default useOnClickOutsideRef;
