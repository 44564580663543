import kebabCase from 'lodash.kebabcase';

const HOST = process.env.NEXT_PUBLIC_NEXT_HOST;

const getSearchLinkFromAutocomplete = (
  result: UnifiedAutocomplete | null
): string => {
  const searchUrl = new URL(`${HOST}/search`);
  searchUrl.searchParams.set('sortBy', 'recommended');

  const filters: Record<string, string | [number, number]> = {};

  if (!result) {
    return searchUrl.toString();
  }

  if (result.type === 'place') {
    switch (result.placeType) {
      case 'state':
        filters.region = result.region;
        searchUrl.searchParams.set(`filters`, JSON.stringify(filters));
        searchUrl.searchParams.set(`searchQuery`, result.regionName);
        return searchUrl.toString();
      default:
        filters.around = `${result.coordinates.lon},${result.coordinates.lat}`;
        searchUrl.searchParams.set(`filters`, JSON.stringify(filters));

        searchUrl.searchParams.set(`searchQuery`, result.name);
        return searchUrl.toString();
    }
  }

  if (result.regionName) {
    return `${HOST}/camping/${kebabCase(result.regionName)}/${result.slug}`;
  }

  return `${HOST}/camping`;
};

export default getSearchLinkFromAutocomplete;
