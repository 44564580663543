import React from 'react';

import AppIcon from 'components/AppIcon/AppIcon';

import SocialLinkList from './assets/social-links.json';

import styles from './styles/AppFooter.module.scss';

export interface _SocialLinksProps {
  className?: string;
}

export const SocialLinks: React.FC<_SocialLinksProps> = ({
  className,
}: _SocialLinksProps) => {
  const listItems = SocialLinkList.map((listItem) => (
    <li className={styles['social-links__item']} key={listItem.path}>
      <a
        className={styles['social-links__link']}
        href={listItem.path || '/'}
        target="_blank"
        rel="noreferrer"
        data-event={listItem.dataEvent}
      >
        <AppIcon
          classNameSvg={[styles['social-links__icon']]}
          icon={listItem.icon}
        />
      </a>
    </li>
  ));

  return (
    <ul className={`${styles['social-links']} ${className}`}>{listItems}</ul>
  );
};

export default SocialLinks;
