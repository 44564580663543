import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import { Router } from 'next/router';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';
import debounce from 'lodash.debounce';

import { WEB_TO_APP_CLICK } from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useUser } from 'contexts/currentUser';
import { useNavDrawerContext } from 'contexts/navDrawer';

import webToAppLink from 'utils/webToAppUrl';

import useIsMobileDevice from 'hooks/useIsMobileDevice';

import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';
import AppSearch from 'components/AppSearch/AppSearch';

import _Nav from './_Nav';
import _NavLoginLogout from './_NavLoginLogout';

import AppHeaderNavDrawer from './AppHeaderNavDrawer';

import styles from './styles/AppHeader.module.scss';

const cx = classNames.bind(styles);

export type AppHeaderHide = 'mobile' | 'desktop' | boolean;

export interface AppHeaderProps {
  hide?: AppHeaderHide;
  disableSearch?: boolean;
  className?: string;
}

export const AppHeader: React.FC<AppHeaderProps> = ({
  hide,
  disableSearch = false,
  className,
}: AppHeaderProps) => {
  const { isNavOpen } = useNavDrawerContext();
  const [searchOpen, setSearch] = useState(false);

  const mobileDevice = useIsMobileDevice();
  const pathname = usePathname();
  const { user, proUpgradeSidebarOpen } = useUser();

  const featureParam = 'top_mobile_nav';
  const deepLinkUrl = webToAppLink({ featureParam });

  const onHomePage = pathname === '/';

  const headerClassNames = cx({
    header: true,
    'header--hide': hide === true,
    'header--underlay': proUpgradeSidebarOpen === true,
    'header--hide-mobile': hide === 'mobile' || (!isNavOpen && onHomePage),
    'header--hide-desktop': hide === 'desktop',
    'header--nav-drawer-open': isNavOpen,
  });

  const searchContainerClassNames = cx({
    'search-container': true,
    'search-container--is-open': searchOpen,
  });

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 900) {
        setSearch(false);
        document.body.classList.remove('has-overlay');
      }
    };

    handleResize();
    const debouncedHandleResize = debounce(handleResize, 500);

    window.addEventListener('resize', debouncedHandleResize, { passive: true });

    const closeOnRouteChange = () => setSearch(false);
    Router.events.on('routeChangeComplete', closeOnRouteChange);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      Router.events.off('routeChangeComplete', closeOnRouteChange);
    };
  }, []);

  return (
    <header className={`${headerClassNames} ${className}`} id="app-header">
      <div className={styles['header__interior']}>
        <div className={styles['header__primary']}>
          <AppHeaderNavDrawer />
          <div className={styles['header__primary-interior']}>
            {disableSearch}
            <AppLink href={`/`}>
              <a
                className={styles['logo-link']}
                data-event="Consumer_Header_Dyrt-Logo"
              >
                <Image
                  className={styles.logo}
                  src={`${PUBLIC_ASSETS_PATH}/assets/brand/horizontal/logo-color_brown.svg`}
                  alt="The Dyrt – find campgrounds for tents, RVs, cabins, glamping and more."
                  width={165}
                  height={50}
                  loading="eager"
                />
              </a>
            </AppLink>
            {!disableSearch && (
              <div className={searchContainerClassNames}>
                <AppSearch
                  className={styles.search}
                  placeholder={'Where would you like to camp?'}
                />
              </div>
            )}
          </div>
          {!disableSearch && !mobileDevice && (
            <AppIcon
              classNameWrapper={styles['search-toggle']}
              icon="magnifying_glass"
              onClick={() => {
                setSearch(!searchOpen);
              }}
              data-event="click_toggle_search_bar"
            />
          )}
          {mobileDevice && (
            <AppButtonLink
              className={styles['header__openApp']}
              label={'Open in App'}
              path={deepLinkUrl}
              dataEvent={'web_to_app_mobile_header'}
              onClick={() => {
                amplitude.track(WEB_TO_APP_CLICK, {
                  'page name': 'Top Mobile Navigation',
                  'click label': 'Open in App',
                });
              }}
            />
          )}
          <_NavLoginLogout
            className={user ? styles['logged-in'] : styles['logged-out']}
          />
        </div>
        <div className={styles['header__secondary']}>
          <_Nav />
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
