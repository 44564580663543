import React, { useState } from 'react';
import { signOut } from 'next-auth/react';
import { usePathname } from 'next/navigation';
import * as amplitude from '@amplitude/analytics-browser';
import classNames from 'classnames/bind';

import { PRO_CTA_CLICK } from 'constants/amplitude';

import { useUser } from 'contexts/currentUser';

import getNavLink from 'utils/getNavLink';

import useAmplitudePageName from 'hooks/useAmplitudePageName';
import { useRelativeAuthLinks } from 'hooks/useAuthRedirect';
import useOnClickOutsideRef from 'hooks/useOnClickOutsideRef';

import AppAvatar from 'components/AppAvatar/AppAvatar';
import AppButtonLink from 'components/AppButton/AppButtonLink';
import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';

import profileItems from './assets/profile';

import styles from './styles/AppHeader.module.scss';

const cx = classNames.bind(styles);

export interface NavLoginLogoutProps {
  className?: string;
}

const NavLoginLogout: React.FC<NavLoginLogoutProps> = ({
  className,
}: NavLoginLogoutProps) => {
  const { user, loading } = useUser();
  const { signInLink, signUpLink } = useRelativeAuthLinks();
  const pathname = usePathname();

  const pageName = useAmplitudePageName();

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const popoverRef = useOnClickOutsideRef(() => {
    if (isDropdownOpen) {
      setDropdownOpen(false);
    }
  });

  if (loading) return null;

  const profileDropdownClasses = cx({
    'profile-menu__list': true,
    'profile-menu__list--open': isDropdownOpen,
  });

  const profileDropdownDeviderClasses = cx({
    'profile-menu': true,
    'profile-menu--secondary-group': !!user?.pro,
  });

  const sendProCTAClickEvent = () => {
    amplitude.track(PRO_CTA_CLICK, {
      'button name': 'Nav Menu Try Pro',
      'page name': pageName,
    });
  };

  if (!user) {
    return (
      <div
        className={`${styles['login']} ${styles['logged-out']} ${className}`}
      >
        <AppButtonLink
          className={`${styles['login__button']}`}
          path={signInLink}
          label="Sign In"
          ghost="action"
          dataEvent="click_nav_sign_in"
          skipInterstitalAd
        />
        <AppButtonLink
          className={`${styles['login__button']}`}
          path={signUpLink}
          label="Join"
          dataEvent="click_nav_join"
          skipInterstitalAd
        />
      </div>
    );
  }

  return (
    <div className={`${styles['logged-in']} ${className}`} ref={popoverRef}>
      {user && user.manager && (
        <>
          {pathname.includes('/search') ? (
            <AppLink href={getNavLink('/manager', user)}>
              <a
                className={`${styles['logged-in__action']} ${styles['logged-in__action--is-manager']}`}
                data-event="click_nav_manager"
                data-google-interstitial="false"
              >
                <AppIcon
                  classNameWrapper={styles['logged-in__action-icon-wrapper']}
                  classNameSvg={[`${styles['logged-in__action-icon']}`]}
                  icon="calendar"
                />
                Manager Portal
              </a>
            </AppLink>
          ) : (
            <AppButtonLink
              className={styles['logged-in__manager-link']}
              path={getNavLink('/manager', user)}
              label="Manager Portal"
              dataEvent="click_nav_manager"
              skipInterstitalAd
            />
          )}
        </>
      )}

      <nav className={styles.nav}>
        <ul className={` ${styles['profile-menu']}`}>
          <AppAvatar
            className={styles['logged-in__avatar']}
            user={user}
            size="sm"
            hideInfo
            onClick={() => {
              setDropdownOpen(!isDropdownOpen);
            }}
          />
          <div>
            <ul className={profileDropdownClasses}>
              <div className={styles['profile-menu__container--primary']}>
                {profileItems.map((proItem) => (
                  <li
                    className={`${styles['profile-menu__item']} `}
                    key={proItem.label}
                  >
                    <AppLink
                      href={getNavLink(proItem.path, user)}
                      external={proItem.external}
                    >
                      <a
                        className={styles['profile-menu__link']}
                        data-event={proItem.dataEvent}
                      >
                        <AppIcon
                          classNameSvg={
                            proItem.icon === 'alerts'
                              ? [`${styles['profile-menu__link-icon--alerts']}`]
                              : [`${styles['profile-menu__link-icon']}`]
                          }
                          category={proItem.category}
                          icon={proItem.icon}
                        />
                        {proItem.label}
                      </a>
                    </AppLink>
                  </li>
                ))}
              </div>

              {!user?.pro && (
                <li
                  className={`${styles['profile-menu__item--pro']} `}
                  key={'proLink'}
                >
                  <AppLink href={`/pro/checkout`}>
                    <a
                      className={styles['profile-menu__link--pro']}
                      data-event={'click_nav_try_pro_for_free'}
                      onClick={sendProCTAClickEvent}
                      data-google-interstitial="false"
                    >
                      <span
                        className={`${styles['profile-menu__item--pro-text']} `}
                      >
                        Try
                      </span>
                      &nbsp;{' '}
                      <AppIcon
                        classNameSvg={[`${styles['profile-menu__link-icon']}`]}
                        icon={'pro_pill'}
                      />{' '}
                      <span
                        className={`${styles['profile-menu__item--pro-text']} `}
                      >
                        for free
                      </span>
                    </a>
                  </AppLink>
                </li>
              )}
              <div className={profileDropdownDeviderClasses}>
                {' '}
                <div className={styles['profile-menu__container']}>
                  <li
                    className={`${styles['profile-menu__item--secondary-item']} `}
                    key={'Support'}
                  >
                    <AppLink href={'https://support.thedyrt.com/hc/en-us'}>
                      <a
                        className={styles['profile-menu__link--secondary']}
                        data-event={'click_nav_support'}
                        data-google-interstitial="false"
                      >
                        Support
                      </a>
                    </AppLink>
                  </li>

                  <li
                    className={`${styles['profile-menu__item--secondary-item']} `}
                    key={'Sign Out'}
                  >
                    <a
                      className={styles['profile-menu__link--secondary']}
                      data-event={'click_sign_out'}
                      onClick={() => signOut()}
                      data-google-interstitial="false"
                    >
                      Sign Out
                    </a>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default NavLoginLogout;
