import React from 'react';

import AppLink from 'components/AppLink/AppLink';

import legalLinks from './assets/legal-links.json';

import styles from './styles/AppFooter.module.scss';

const HOST = process.env.NEXT_PUBLIC_EMBER_HOST;

export interface _LegalProps {
  className?: string;
}

export const Legal: React.FC<_LegalProps> = ({ className }: _LegalProps) => {
  const date = new Date().getFullYear();

  return (
    <div className={`${styles.legal} ${className}`}>
      <p className={styles['legal__copyright']}>
        © {date} The Dyrt, Inc | All rights reserved
      </p>
      <ul className={styles['legal__list']}>
        {legalLinks.map((listItem) => (
          <li className={styles['legal__item']} key={listItem.label}>
            <AppLink href={listItem.path.replace(':host', HOST || '/') || '/'}>
              <a
                className={styles['legal__link']}
                data-event="Consumer_Footer_Legal-Link"
              >
                {listItem.label}
              </a>
            </AppLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Legal;
