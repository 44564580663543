import React from 'react';
import Image from 'next/image';

import { QUICK_LINK_TO_APP } from 'constants/branch';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import styles from './styles/AppPlayStore.module.scss';

export interface AppPlayStoreProps {
  className?: string;
}

export const AppPlayStore: React.FC<AppPlayStoreProps> = ({
  className,
}: AppPlayStoreProps) => {
  return (
    <a
      href={QUICK_LINK_TO_APP}
      className={`${className} ${styles['google']}`}
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src={`${PUBLIC_ASSETS_PATH}/assets/images/google-play@2x.png`}
        alt="Google Play"
        data-event="click_footer_android_app"
        className={`${styles['google__image']} ${styles['google__image--google']}`}
        width={140}
        height={42}
      />
    </a>
  );
};

export default AppPlayStore;
