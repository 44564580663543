import React, { InputHTMLAttributes, useState } from 'react';
import classNames from 'classnames/bind';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppInput.module.scss';

const cx = classNames.bind(styles);

export interface AppInputSearchProps
  extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  error?: boolean;
  icon?: string;
  label?: string;
  large?: boolean;
  onChange(e: React.ChangeEvent<HTMLInputElement>): void;
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void;
  placeholder?: string;
  required?: boolean;
  value: string;
  hideClear?: boolean;
  name?: string;
  dataTestId?: string;
}

export const AppInputSearch = React.forwardRef<
  HTMLInputElement,
  AppInputSearchProps
>(
  (
    {
      className,
      error,
      icon,
      label,
      large,
      onChange,
      onKeyUp,
      placeholder,
      required,
      value,
      hideClear,
      name,
      dataTestId,
      ...inputProps
    }: AppInputSearchProps,
    ref
  ) => {
    const [hasFocus, setHasFocus] = useState<boolean>(false);

    const inputClassNames = cx({
      input__field: true,
      'input__field--has-icon': icon,
      'input__field--is-large': large,
      'input__field--has-error': error,
      'input__field--hide-clear': hideClear,
    });

    const inputIconClassNames = cx({
      input__icon: true,
      'input__icon--is-large': large,
      'input__icon--focused': hasFocus,
    });

    return (
      <label className={`${styles.input} ${className}`}>
        <span
          className={`${styles['input__label']} ${
            !label ? styles['input__label--empty'] : ''
          }`}
        >
          {label}
        </span>
        <span className={styles['input__container']}>
          {icon && (
            <AppIcon classNameWrapper={inputIconClassNames} icon={icon} />
          )}
          <input
            className={inputClassNames}
            type="search"
            value={value}
            placeholder={placeholder}
            ref={ref}
            onChange={onChange}
            onKeyUp={onKeyUp}
            required={required}
            onBlur={() => {
              setHasFocus(false);
            }}
            onFocus={() => {
              setHasFocus(true);
              if (ref && 'current' in ref && ref.current) {
                ref.current.select();
              }
            }}
            name={name}
            autoComplete="off"
            data-testid={dataTestId}
            {...inputProps}
          />
        </span>
      </label>
    );
  }
);

AppInputSearch.displayName = 'AppInputSearch';

export default AppInputSearch;
