import React from 'react';
import classNames from 'classnames/bind';

import { useNavDrawerContext } from 'contexts/navDrawer';

import AppHamburger from 'components/AppHamburger/AppHamburger';

import styles from './styles/AppNavDrawer.module.scss';

const cx = classNames.bind(styles);

interface Props {
  renderContent: (isNavOpen: boolean) => JSX.Element;
  navClassName?: string;
  toggleLight?: boolean;
}

const AppNavDrawer: React.FC<Props> = ({
  renderContent,
  navClassName,
  toggleLight,
}) => {
  const { isNavOpen } = useNavDrawerContext();

  const classNames = cx({
    'drawer--open': isNavOpen,
  });

  return (
    <div className={classNames}>
      <nav className={`${styles['drawer']} ${navClassName} nav-drawer`}>
        {renderContent(isNavOpen)}
      </nav>
      <AppHamburger toggleLight={toggleLight} />
    </div>
  );
};

export default AppNavDrawer;
