import React from 'react';

import { useLogAmplitudeOnNavClick } from 'hooks/useLogAmplitudeOnNavClick';
import { useOverlayContext } from 'hooks/useOverlayContext';

import AppLink from 'components/AppLink/AppLink';

import styles from './styles/AppFooter.module.scss';

const HOST = process.env.NEXT_PUBLIC_EMBER_HOST;

export interface LinkListProps {
  title: string;
  items: LinkType[];
}

export const LinkList: React.FC<LinkListProps> = ({
  title,
  items,
}: LinkListProps) => {
  const logAmplitudeOnNavClick = useLogAmplitudeOnNavClick();

  const { setOverlay } = useOverlayContext<AppCampgroundGatingModal>();

  const listItems = items.map((listItem) => (
    <li className={styles['link-list__item']} key={listItem.label}>
      <AppLink href={listItem.path.replace(':host', HOST || '/') || '/'}>
        <a
          className={styles['link-list__link']}
          data-event={listItem.dataEvent}
          onClick={(e) => {
            if (listItem.label === 'Add a Location') {
              e.preventDefault();
              setOverlay({ type: 'add' });
            }
            listItem.eventName && logAmplitudeOnNavClick(listItem);
          }}
        >
          {listItem.label}
        </a>
      </AppLink>
    </li>
  ));

  return (
    <div className={styles['link-list']}>
      <h4 className={styles['link-list__title']}>{title}</h4>
      <ul className={styles['link-list__list']}>{listItems}</ul>
    </div>
  );
};

export default LinkList;
