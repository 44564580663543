import React, { useRef, useEffect, useReducer, useCallback } from 'react';
import { useSession } from 'next-auth/react';
import { usePathname, useParams } from 'next/navigation';
import classNames from 'classnames/bind';
import debounce from 'lodash.debounce';

import { useUser } from 'contexts/currentUser';

import { logAmplitudeWebClick } from 'utils/amplitude';
import { fetchRecommended, search } from 'utils/search';

import { useOverlayContext } from 'hooks/useOverlayContext';

import AppIcon from 'components/AppIcon/AppIcon';
import AppInputSearch from 'components/AppInput/AppInputSearch';
import AppLink from 'components/AppLink/AppLink';

import _ListItem from './_ListItem';
import { reducer } from './reducer';

import styles from './styles/AppSearch.module.scss';

const cx = classNames.bind(styles);

export interface AppSearchProps {
  className?: string;
  resultsClassName?: string;
  hideClear?: boolean;
  large?: boolean;
  placeholder?: string;
  onResultSelected?: (
    result: UnifiedAutocomplete | null,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}

const isCampgroundResult = (
  result: UnifiedAutocomplete
): result is CampgroundAutocomplete => {
  return (result as CampgroundAutocomplete).type === 'campground';
};

export const AppSearch: React.FC<AppSearchProps> = ({
  className,
  resultsClassName = '',
  large,
  placeholder = 'Where to?',
  hideClear,
  onResultSelected: onResultSelectedCb,
}: AppSearchProps) => {
  const pathname = usePathname();

  const { setOverlay } = useOverlayContext<AppCampgroundGatingModal>();

  const { searchQuery = '' } = useParams() || {};

  const { userLocation } = useUser();

  const [searchState, dispatch] = useReducer(reducer, {
    open: false,
    value: searchQuery
      ? (searchQuery as string)
      : pathname === '/search'
      ? 'Near Me'
      : '',
    activeIndex: -2,
    recommended: [] as CampgroundAutocomplete[],
    results: [] as UnifiedAutocomplete[],
    completed: false,
  });

  const {
    activeIndex,
    value: searchStateValue,
    recommended,
    results,
    open: searchOpen,
    completed,
  } = searchState;

  const searchContainerElement = useRef<HTMLDivElement>(null);

  const searchInputElement = useRef<HTMLInputElement>(null);

  const resultElement = useRef<HTMLAnchorElement[]>([]);

  const { data: sessionData } = useSession() || {};
  const token = sessionData?.accessToken;

  const debouncedSearch = useRef(
    debounce(
      (query: string) => {
        const headers = {
          'x-access-token': token as string,
          Authorization: `Bearer ${token}` as string,
        };

        search(query, 'unified', {}, headers)
          .then((res) => {
            dispatch({
              type: 'SET_RESULTS',
              payload: res as UnifiedAutocomplete[],
            });
          })
          .catch((e) => {
            console.error(e);
          });
      },
      250,
      { leading: true, trailing: true }
    )
  );

  useEffect(() => {
    document.addEventListener('mousedown', searchClick);

    return () => {
      document.removeEventListener('mousedown', searchClick);
    };
  }, []);

  useEffect(() => {
    let mounted = true;
    if (!userLocation) return;
    fetchRecommended(userLocation.lat, userLocation.long).then((res) => {
      if (mounted) {
        dispatch({
          type: 'SET_RECOMMENDED',
          payload: res,
        });
      }
    });
    return () => {
      mounted = false;
    };
  }, [userLocation]);

  useEffect(() => {
    if (activeIndex === -1) {
      searchInputElement.current?.focus();
    } else if (activeIndex > -1) {
      resultElement.current[activeIndex].focus();
    }
  }, [searchInputElement, resultElement, activeIndex]);

  useEffect(() => {
    if (searchStateValue.length < 2) {
      dispatch({
        type: 'SET_RESULTS',
        payload: [],
      });
    } else if (searchStateValue.length >= 2) {
      debouncedSearch.current(searchStateValue);
    }
  }, [searchStateValue]);

  const searchClick = (e: MouseEvent) => {
    if (e.target instanceof HTMLElement) {
      const newOpenState =
        !!searchContainerElement.current &&
        searchContainerElement.current.contains(e.target);
      dispatch({
        type: 'SET_OPEN',
        payload: newOpenState,
      });
    }
  };

  const changeActiveIndex = useCallback(
    (e: React.KeyboardEvent<HTMLAnchorElement | HTMLInputElement>) => {
      if (e.key === 'ArrowUp' && activeIndex > -1) {
        dispatch({
          type: 'SET_ACTIVE_INDEX',
          payload: activeIndex - 1,
        });
      } else if (
        e.key === 'ArrowDown' &&
        activeIndex < resultElement.current.length - 1
      ) {
        dispatch({
          type: 'SET_ACTIVE_INDEX',
          payload: activeIndex + 1,
        });
      }
    },
    [activeIndex]
  );

  const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'SEARCH_INPUT_CHANGE',
      payload: e.target.value,
    });
  };

  const onResultSelected = (
    result: UnifiedAutocomplete | null,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    dispatch({
      type: 'ON_RESULT_SELECTED',
      payload: result?.name || 'Near Me',
    });

    if (onResultSelectedCb) {
      onResultSelectedCb(result, e);
    }

    logAmplitudeWebClick(result?.name || 'Near Me', 'search_result_selected');
  };

  const resultsClassNames = cx(resultsClassName, {
    results: true,
    'results--is-open': searchOpen,
  });

  return (
    <div
      className={`${styles.search} ${className}`}
      ref={searchContainerElement}
      onClick={() => {
        dispatch({
          type: 'SET_ACTIVE_INDEX',
          payload: -1,
        });
      }}
      data-event="click_focus_search_bar"
    >
      <AppInputSearch
        className={styles['search__input']}
        value={searchStateValue}
        large={large}
        icon="magnifying_glass"
        placeholder={placeholder}
        ref={searchInputElement}
        onChange={onSearchInputChange}
        onKeyUp={changeActiveIndex}
        hideClear={hideClear}
      />
      <div className={resultsClassNames}>
        <ul className={styles['results__list']}>
          {searchStateValue.length < 2 && (
            <>
              <li className={styles['results__item']}>
                <AppLink href="/search">
                  <a
                    className={styles['results__link']}
                    ref={(element) => {
                      if (element) {
                        resultElement.current[0] = element;
                      }
                    }}
                    data-event="click_search_near_me"
                    onClick={(e) => {
                      onResultSelected(null, e);
                    }}
                    onKeyUp={changeActiveIndex}
                  >
                    <AppIcon
                      classNameWrapper={styles['results__icon-container']}
                      classNameSvg={[`${styles['results__icon']}`]}
                      icon="location_arrow"
                    />
                    Near Me
                  </a>
                </AppLink>
              </li>
              <li
                className={`${styles['results__item']} ${styles['results__item--is-section']}`}
              >
                {recommended.length && (
                  <>
                    <span className={styles['results__section-title']}>
                      Recommended
                    </span>
                    <ul className={styles['results__section-list']}>
                      {recommended
                        .filter(isCampgroundResult)
                        .map(
                          (
                            campground: CampgroundAutocomplete,
                            index: number
                          ) => (
                            <_ListItem
                              result={campground}
                              index={index}
                              resultElementRef={resultElement}
                              changeActiveIndex={changeActiveIndex}
                              data_event="click_search_recommended"
                              key={index}
                              onResultSelected={onResultSelected}
                            />
                          )
                        )}
                    </ul>
                  </>
                )}
              </li>
            </>
          )}
          {!!results.length &&
            results.map((result: UnifiedAutocomplete, index: number) => (
              <_ListItem
                result={result}
                index={index}
                resultElementRef={resultElement}
                onResultSelected={onResultSelected}
                changeActiveIndex={changeActiveIndex}
                data_event="click_search_result"
                key={index}
              />
            ))}
          {searchStateValue.length >= 2 && results.length === 0 && completed && (
            <li
              className={`${styles['results__item']} ${styles['results__item--is-section']}`}
            >
              <span className={styles['results__section-title']}>
                No Results
              </span>
              <a
                className={styles['results__none']}
                onClick={() => {
                  setOverlay({ type: 'add' });
                }}
                ref={(element) => {
                  if (element) {
                    resultElement.current[0] = element;
                  }
                }}
                onKeyUp={changeActiveIndex}
              >
                Don&apos;t see your campground? Add it here.
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AppSearch;
