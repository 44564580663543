export const PARKS = [
  {
    path: '/camping/wyoming/near/yellowstone-national-park',
    label: 'Yellowstone National Park',
    dataEvent: 'click_yellowstone_national_park',
  },
  {
    path: '/camping/california/near/yosemite-national-park',
    label: 'Yosemite National Park',
    dataEvent: 'click_yosemite_national_park',
  },
  {
    path: '/camping/arizona/near/grand-canyon-national-park',
    label: 'Grand Canyon National Park',
    dataEvent: 'click_grand_canyon_national_park',
  },
  {
    path: '/camping/utah/near/zion-national-park',
    label: 'Zion National Park',
    dataEvent: 'click_zion_national_park',
  },
  {
    path: '/camping/california/near/joshua-tree-national-park',
    label: 'Joshua Tree National Park',
    dataEvent: 'click_joshua_tree_national_park',
  },
  {
    path: '/camping/california/near/sequoia-and-kings-canyon-national-parks',
    label: 'Sequoia National Park',
    dataEvent: 'click_sequoia_national_parks',
  },
  {
    path: '/camping/maine/near/acadia-national-park',
    label: 'Acadia National Park',
    dataEvent: 'click_acadia_national_park',
  },
  {
    path: '/camping/montana/near/glacier-national-park',
    label: 'Glacier National Park',
    dataEvent: 'click_glacier_national_park',
  },
  {
    path: '/camping/wyoming/near/grand-teton-national-park',
    label: 'Grand Teton National Park',
    dataEvent: 'click_grand_teton_national_park',
  },
  {
    path: '/camping/north-carolina/near/great-smoky-mountains-national-park',
    label: 'Great Smoky Mountains National Park',
    dataEvent: 'click_great_smoky_mountains_national_park',
  },
];

export const CITIES = [
  {
    path: '/camping/north-carolina/near/asheville',
    label: 'Asheville, NC',
    dataEvent: 'click_asheville',
  },
  {
    path: '/camping/oregon/near/bend',
    label: 'Bend, OR',
    dataEvent: 'click_bend',
  },
  {
    path: '/camping/utah/near/moab',
    label: 'Moab, UT',
    dataEvent: 'click_moab',
  },
  {
    path: '/camping/minnesota/near/duluth',
    label: 'Duluth, MN',
    dataEvent: 'click_duluth',
  },
  {
    path: '/camping/california/near/san-diego',
    label: 'San Diego, CA',
    dataEvent: 'click_san_diego',
  },
  {
    path: '/camping/georgia/near/savannah',
    label: 'Savannah, GA',
    dataEvent: 'click_savannah',
  },
  {
    path: '/camping/illinois/near/chicago',
    label: 'Chicago, IL',
    dataEvent: 'click_chicago',
  },
  {
    path: '/camping/colorado/near/colorado-springs',
    label: 'Colorado Springs, CO',
    dataEvent: 'click_colorado_springs',
  },
  {
    path: '/camping/tennessee/near/gatlinburg',
    label: 'Gatlinburg, TN',
    dataEvent: 'click_gatlinburg',
  },
  {
    path: '/camping/california/near/san-francisco',
    label: 'San Francisco, CA',
    dataEvent: 'click_san_francisco',
  },
];

export const STATES = [
  {
    path: '/camping/california',
    label: 'California',
    dataEvent: 'click_california',
  },
  {
    path: '/camping/colorado',
    label: 'Colorado',
    dataEvent: 'click_colorado',
  },
  {
    path: '/camping/florida',
    label: 'Florida',
    dataEvent: 'click_florida',
  },
  {
    path: '/camping/michigan',
    label: 'Michigan',
    dataEvent: 'click_michigan',
  },
  {
    path: '/camping/minnesota',
    label: 'Minnesota',
    dataEvent: 'click_minnesota',
  },
  {
    path: '/camping/new-york',
    label: 'New York',
    dataEvent: 'click_new_york',
  },
  {
    path: '/camping/oregon',
    label: 'Oregon',
    dataEvent: 'click_oregon',
  },
  {
    path: '/camping/texas',
    label: 'Texas',
    dataEvent: 'click_texas',
  },
  {
    path: '/camping/washington',
    label: 'Washington',
    dataEvent: 'click_washington',
  },
  {
    path: '/camping/wisconsin',
    label: 'Wisconsin',
    dataEvent: 'click_wisconsin',
  },
];

export const FORESTS = [
  {
    path: '/camping/illinois/near/shawnee-national-forest',
    label: 'Shawnee National Forest',
    datEvent: 'click_shawnee_national_forest',
  },
  {
    path: '/camping/kentucky/near/daniel-boone-national-forest',
    label: 'Daniel Boone National Forest',
    dataEvent: 'click_daniel_boone_national_forest',
  },
  {
    path: '/camping/north-carolina/near/pisgah-national-forest',
    label: 'Pisgah National Forest',
    dataEvent: 'click_pisgah_national_forest',
  },
  {
    path: '/camping/california/near/sequoia-national-forest',
    label: 'Sequoia National Forest',
    dataEvent: 'click_sequoia_national_forest',
  },
  {
    path: '/camping/pennsylvania/near/allegheny-national-forest',
    label: 'Allegheny National Forest',
    dataEvent: 'click_allegheny_national_forest',
  },
  {
    path: '/camping/missouri/near/mark-twain-national-forest',
    label: 'Mark Twain National Forest',
    dataEvent: 'click_mark_twain_national_forest',
  },
  {
    path: '/camping/wyoming/near/bighorn-national-forest',
    label: 'Bighorn National Forest',
    dataEvent: 'click_bighorn_national_forest',
  },
  // {
  //   path: '/camping/arkansas/near/ozark-national-forest',
  //   label: 'Ozark National Forest',
  //   dataEvent: 'click_ozark_national_forest',
  // },
  {
    path: '/camping/washington/near/olympic-national-forest',
    label: 'Olympic National Forest',
    dataEvent: 'click_olympic_national_forest',
  },
  {
    path: '/camping/oregon/near/mt-hood-national-forest',
    label: 'Mount Hood National Forest',
    dataEvent: 'click_mt_hood_national_forest',
  },
];
