import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import * as amplitude from '@amplitude/analytics-browser';

import {
  CLAIM_CTA_CLICK,
  PRO_CTA_CLICK,
  DYRT_ALERTS_CTA_CLICK,
} from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useUser } from 'contexts/currentUser';

import getNavLink from 'utils/getNavLink';

import useAmplitudePageName from 'hooks/useAmplitudePageName';

import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';

import { PARKS, CITIES, STATES, FORESTS } from './assets/nav';
import { MORE } from './assets/more';
import _NavDrawerLoginLogout from './_NavDrawerLoginLogout';
import _NavDrawerUserMenu from './_NavDrawerUserMenu';

import styles from './styles/_NavDrawerContent.module.scss';

export interface NavDrawerProps {
  isOpen: boolean;
}

const _NavDrawerContent: React.FC<NavDrawerProps> = ({
  isOpen,
}: NavDrawerProps) => {
  const { user } = useUser();

  const userIsPro = user?.pro;

  const [activePanel, setPanel] = useState('');

  const pageName = useAmplitudePageName();

  const createDyrtAlertPath =
    userIsPro && user.remainingProScans < user.totalProScans
      ? '/alerts/my-alerts'
      : '/alerts';

  useEffect(() => {
    if (!isOpen) {
      setPanel('');
    }
  }, [isOpen]);

  const onButtonClick = () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
      amplitude.track(CLAIM_CTA_CLICK, {
        'button name': 'Header - List Your Campground',
        'page name': pageName,
      });
    }
  };

  const onProUpsellClick = () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
      amplitude.track(PRO_CTA_CLICK, {
        'button name': 'Nav Menu Try Pro',
        'page name': pageName,
      });
    }
  };

  const onProMapsClick = () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY && !userIsPro) {
      amplitude.track(PRO_CTA_CLICK, {
        'button name': 'Unlock PRO Benefits',
        'page name': pageName,
      });
    }
  };

  return (
    <>
      <div className={styles['main-menu']}>
        {user && (
          <div className={styles['header']}>
            <_NavDrawerUserMenu />
          </div>
        )}
        {user && !user.pro && !user.manager && (
          <AppLink href={getNavLink('/pro/checkout', user)}>
            <a
              className={styles['banner']}
              data-event="click_nav_free"
              onClick={onProUpsellClick}
              data-google-interstitial="false"
            >
              {user?.name.split(' ').shift()}, try{' '}
              <Image
                className={styles[`banner__pro-badge`]}
                src={`${PUBLIC_ASSETS_PATH}/assets/badges/pro.svg`}
                alt={`The Dyrt Pro`}
                width={33}
                height={16}
              />{' '}
              membership for free
            </a>
          </AppLink>
        )}
        <ul className={styles['list']}>
          <li
            className={styles['list__item']}
            onClick={() => setPanel(`Find Camping${0}`)}
          >
            Find Camping
            <AppIcon
              icon="chevron_right"
              data-event="click_open_header_panel"
            />
          </li>
          <li className={styles['list__item']}>
            <AppLink href={createDyrtAlertPath}>
              <a
                className={styles['list__nav-link']}
                onClick={() => {
                  amplitude.track(DYRT_ALERTS_CTA_CLICK, {
                    'page name': pageName,
                    'button name': 'Header - Create a Dyrt Alert',
                  });
                }}
                data-event="click_nav_create_a_dyrt_alert"
              >
                Create a Dyrt Alert
              </a>
            </AppLink>
            <AppIcon icon="chevron_right" />
          </li>
          <li className={styles['list__item']}>
            <AppLink href={getNavLink('/pro')}>
              <a
                className={styles['list__nav-link']}
                onClick={onProMapsClick}
                data-event="click_open_header_panel"
              >
                {userIsPro ? 'My PRO Benefits' : 'Unlock PRO Benefits'}
              </a>
            </AppLink>
            <AppIcon icon="chevron_right" />
          </li>
          <li className={styles['list__item']}>
            <AppLink href={getNavLink('/claim')}>
              <a
                className={styles['list__nav-link']}
                onClick={onButtonClick}
                data-event="click_open_header_panel"
                data-google-interstitial="false"
              >
                List Your Property
              </a>
            </AppLink>
            <AppIcon icon="chevron_right" />
          </li>
          <li
            className={styles['list__item']}
            data-event="click_open_header_panel"
            onClick={() => setPanel(`More${0}`)}
          >
            More
            <AppIcon icon="chevron_right" />
          </li>
          <_NavDrawerLoginLogout />
        </ul>
      </div>
      <div
        className={`${styles['panel']} ${
          activePanel === 'Find Camping' + 0 ? styles['panel--is-active'] : ''
        }`}
      >
        <div className={styles['panel__header']}>
          <AppIcon
            classNameSvg={[`${styles['panel__chevron']}`]}
            icon="chevron_left"
            onClick={() => setPanel('')}
            data-event="click_close_header_panel"
          />
          <span
            className={styles['panel__title']}
            onClick={() => setPanel('')}
            data-event="click_close_header_panel"
          >
            Find Camping
          </span>
        </div>
        <ul className={styles['panel__list']}>
          <AppLink
            className={styles['panel_link-container']}
            href={getNavLink('/search')}
            prefetch={false}
          >
            <a className={styles['panel__search-link']}>
              <AppIcon
                category="menu"
                classNameSvg={[`${styles['panel__map-icon']}`]}
                icon="map"
              />
              Explore the Map
            </a>
          </AppLink>
          <li className={styles['panel__item']}>
            <div className={styles['panel__places-title']}>
              Most Searched Parks
            </div>
            {PARKS.map((link) => (
              <AppLink href={getNavLink(link.path, user)} key={link.label}>
                <a
                  className={styles['panel__link']}
                  data-event={link.dataEvent}
                >
                  {link.label}
                </a>
              </AppLink>
            ))}
          </li>
          <li className={styles['panel__item']}>
            <div className={styles['panel__places-title']}>
              Most Searched Cities
            </div>
            {CITIES.map((link) => (
              <AppLink href={getNavLink(link.path, user)} key={link.label}>
                <a
                  className={styles['panel__link']}
                  data-event={link.dataEvent}
                >
                  {link.label}
                </a>
              </AppLink>
            ))}
          </li>
          <li className={styles['panel__item']}>
            <div className={styles['panel__places-title']}>
              Most Searched States
            </div>
            {STATES.map((link) => (
              <AppLink href={getNavLink(link.path, user)} key={link.label}>
                <a
                  className={styles['panel__link']}
                  data-event={link.dataEvent}
                >
                  {link.label}
                </a>
              </AppLink>
            ))}
          </li>
          <div className={styles['panel__forests-container']}>
            <li className={styles['panel__item']}>
              <div className={styles['panel__places-title']}>
                Most Searched Forests
              </div>
              {FORESTS.map((link) => (
                <AppLink href={getNavLink(link.path, user)} key={link.label}>
                  <a
                    className={styles['panel__link']}
                    data-event={link.dataEvent}
                  >
                    {link.label}
                  </a>
                </AppLink>
              ))}
            </li>
          </div>
        </ul>
      </div>
      <div
        className={`${styles['panel']} ${
          activePanel === 'More' + 0 ? styles['panel--is-active'] : ''
        }`}
      >
        <div className={styles['panel__header']}>
          <AppIcon
            classNameSvg={[`${styles['panel__chevron']}`]}
            icon="chevron_left"
            onClick={() => setPanel('')}
            data-event="click_close_header_panel"
          />
          <span
            className={styles['panel__title']}
            onClick={() => setPanel('')}
            data-event="click_close_header_panel"
          >
            More
          </span>
        </div>
        <ul className={styles['panel__list']}>
          <li className={styles['panel__item']}>
            {MORE.map((link) => (
              <AppLink href={getNavLink(link.path, user)} key={link.label}>
                <a className={styles['panel__more-link']}>{link.label}</a>
              </AppLink>
            ))}
          </li>
        </ul>
      </div>
    </>
  );
};

export default _NavDrawerContent;
