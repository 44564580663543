import { CLAIM_CTA_CLICK } from './amplitude';

export const EXPLORE = {
  title: 'Explore',
  links: [
    {
      label: 'Review a Campground',
      path: '/find-to-review',
      dataEvent: 'click_footer_add_a_review',
      icon: 'find',
    },
    {
      label: 'Add a Location',
      path: '/add',
      dataEvent: 'click_footer_add_a_campground',
      icon: 'find',
    },
    {
      label: 'Gift Cards',
      path: '/pro/gift',
      dataEvent: 'click_footer_gift_pro',
      icon: 'menu_gift',
    },
    {
      label: 'Contests',
      path: '/contests/camping',
      dataEvent: 'click_footer_contests',
      icon: 'find',
    },
    {
      label: 'Magazine',
      path: '/magazine',
      dataEvent: 'click_footer_magazine',
      icon: 'find',
    },
  ],
};

export const MEET_THE_DYRT = {
  title: 'Meet The Dyrt',
  links: [
    {
      label: 'List Your Property',
      path: '/claim',
      dataEvent: 'click_footer_claim_a_campground',
      icon: 'campground',
      eventName: CLAIM_CTA_CLICK,
      eventButtonName: 'Footer - List Your Property',
    },
    {
      label: 'Advertise',
      path: 'https://join.thedyrt.com/advertising/',
      dataEvent: 'click_footer_advertise',
    },
    {
      label: 'About Us',
      path: '/about/us',
      dataEvent: 'click_footer_about_us',
      icon: 'about_us',
    },
    {
      label: 'Press',
      path: 'https://press.thedyrt.com',
      dataEvent: 'click_footer_press',
      icon: 'press_kit',
    },
    {
      label: 'Careers',
      path: 'https://the-dyrt.breezy.hr/',
      dataEvent: 'click_footer_jobs',
      icon: 'join_our_team',
    },
    {
      label: 'Support',
      path: 'https://support.thedyrt.com/hc/en-us',
      dataEvent: 'click_footer_faqs',
      icon: 'faq',
    },
  ],
};

export const POPULAR_CITIES_CAMPING = {
  links: [
    {
      path: '/camping/north-carolina/near/asheville',
      label: 'Asheville, NC',
      dataEvent: 'click_asheville',
    },
    {
      path: '/camping/oregon/near/bend',
      label: 'Bend, OR',
      dataEvent: 'click_bend',
    },
    {
      path: '/camping/utah/near/moab',
      label: 'Moab, UT',
      dataEvent: 'click_moab',
    },
    {
      path: '/camping/minnesota/near/duluth',
      label: 'Duluth, MN',
      dataEvent: 'click_duluth',
    },
    {
      path: '/camping/california/near/san-diego',
      label: 'San Diego, CA',
      dataEvent: 'click_san_diego',
    },
    {
      path: '/camping/georgia/near/savannah',
      label: 'Savannah, GA',
      dataEvent: 'click_savannah',
    },
    {
      path: '/camping/illinois/near/chicago',
      label: 'Chicago, IL',
      dataEvent: 'click_chicago',
    },
    {
      path: '/camping/colorado/near/colorado-springs',
      label: 'Colorado Springs, CO',
      dataEvent: 'click_colorado_springs',
    },
    {
      path: '/camping/tennessee/near/gatlinburg',
      label: 'Gatlinburg, TN',
      dataEvent: 'click_gatlinburg',
    },
    {
      path: '/camping/california/near/san-francisco',
      label: 'San Francisco, CA',
      dataEvent: 'click_san_francisco',
    },
    {
      path: '/camping/california/near/santa-barbara',
      label: 'Santa Barbara, CA',
      dataEvent: 'click_santa_barbara',
    },
    {
      path: '/camping/california/near/santa-cruz',
      label: 'Santa Cruz, CA',
      dataEvent: 'click_santa_cruz',
    },
    {
      path: '/camping/texas/near/austin',
      label: 'Austin, TX',
      dataEvent: 'click_austin',
    },
    {
      path: '/camping/south-carolina/near/charleston',
      label: 'Charleston, SC',
      dataEvent: 'click_charleston',
    },
    {
      path: '/camping/colorado/near/denver',
      label: 'Denver, CO',
      dataEvent: 'click_denver',
    },
    {
      path: '/camping/nevada/near/las-vegas',
      label: 'Las Vegas, NV',
      dataEvent: 'click_las_vegas',
    },
    {
      path: '/camping/maryland/near/ocean-city',
      label: 'Ocean City, MD',
      dataEvent: 'click_ocean_city',
    },
    {
      path: '/camping/maine/near/portland',
      label: 'Portland, ME',
      dataEvent: 'click_portland',
    },
    {
      path: '/camping/utah/near/salt-lake-city',
      label: 'Salt Lake City, UT',
      dataEvent: 'click_salt_lake_city',
    },
    {
      path: '/camping/arizona/near/sedona',
      label: 'Sedona, AZ',
      dataEvent: 'click_sedona',
    },
    {
      path: '/camping/idaho/near/boise',
      label: 'Boise, ID',
      dataEvent: 'click_boise',
    },
    {
      path: '/camping/colorado/near/durango',
      label: 'Durango, CO',
      dataEvent: 'click_durango',
    },
    {
      path: '/camping/arizona/near/flagstaff',
      label: 'Flagstaff, AZ',
      dataEvent: 'click_flagstaff',
    },
    {
      path: '/camping/georgia/near/helen',
      label: 'Helen, GA',
      dataEvent: 'click_helen',
    },
    {
      path: '/camping/wyoming/near/jackson',
      label: 'Jackson, WY',
      dataEvent: 'click_jackson',
    },
    {
      path: '/camping/california/near/los-angeles',
      label: 'Los Angeles, CA',
      dataEvent: 'click_los_angeles',
    },
    {
      path: '/camping/new-york/near/niagara-falls',
      label: 'Niagara Falls, NY',
      dataEvent: 'click_niagara_falls',
    },
    {
      path: '/camping/texas/near/san-antonio',
      label: 'San Antonio, TX',
      dataEvent: 'click_san_antonio',
    },
    {
      path: '/camping/washington/near/seattle',
      label: 'Seattle, WA',
      dataEvent: 'click_seattle',
    },
    {
      path: '/camping/colorado/near/steamboat-springs',
      label: 'Steamboat Springs, CO',
      dataEvent: 'click_steamboat_springs',
    },
    {
      path: '/camping/idaho/near/twin-falls',
      label: 'Twin Falls, ID',
      dataEvent: 'click_twin_falls',
    },
    {
      path: '/camping/new-mexico/near/albuquerque',
      label: 'Albuquerque, NM',
      dataEvent: 'click_albuquerque',
    },
    {
      path: '/camping/oregon/near/astoria',
      label: 'Astoria, OR',
      dataEvent: 'click_astoria',
    },
    {
      path: '/camping/montana/near/bozeman',
      label: 'Bozeman, MN',
      dataEvent: 'click_bozeman',
    },
    {
      path: '/camping/vermont/near/burlington',
      label: 'Burlington, VT',
      dataEvent: 'click_burlington',
    },
    {
      path: '/camping/north-carolina/near/charlotte',
      label: 'Charlotte, NC',
      dataEvent: 'click_charlotte',
    },
    {
      path: '/camping/tennessee/near/chattanooga',
      label: 'Chattanooga, TN',
      dataEvent: 'click_chattanooga',
    },
    {
      path: '/camping/idaho/near/coeur-d-alene',
      label: 'Coeur D Alene, ID',
      dataEvent: 'click_coeur_d_alene',
    },
    {
      path: '/camping/iowa/near/des-moines',
      label: 'Des Moines, IA',
      dataEvent: 'click_des_moines',
    },
    {
      path: '/camping/florida/near/destin',
      label: 'Destin, FL',
      dataEvent: 'click_destin',
    },
    {
      path: '/camping/colorado/near/estes-park',
      label: 'Estes Park, CO',
      dataEvent: 'click_estes_park',
    },
    {
      path: '/camping/texas/near/houston',
      label: 'Houston, TX',
      dataEvent: 'click_houston',
    },
    {
      path: '/camping/indiana/near/indianapolis',
      label: 'Indianapolis, IN',
      dataEvent: 'click_indianapolis',
    },
    {
      path: '/camping/florida/near/jacksonville',
      label: 'Jacksonville, FL',
      dataEvent: 'click_jacksonville',
    },
    {
      path: '/camping/tennessee/near/knoxville',
      label: 'Knoxville, TN',
      dataEvent: 'click_knoxville',
    },
    {
      path: '/camping/washington/near/leavenworth',
      label: 'Leavenworth, WA',
      dataEvent: 'click_leavenworth',
    },
    {
      path: '/camping/kentucky/near/louisville',
      label: 'Louisville, KY',
      dataEvent: 'click_louisville',
    },
    {
      path: '/camping/michigan/near/marquette',
      label: 'Marquette, MI',
      dataEvent: 'click_marquette',
    },
    {
      path: '/camping/michigan/near/munising',
      label: 'Munising, MN',
      dataEvent: 'click_group',
    },
    {
      path: '/camping/new-york/near/new-york',
      label: 'New York, NY',
      dataEvent: 'click_new_york',
    },
    {
      path: '/camping/nebraska/near/omaha',
      label: 'Omaha, NE',
      dataEvent: 'click_omaha',
    },
    {
      path: '/camping/oregon/near/portland',
      label: 'Portland, OR',
      dataEvent: 'click_portland',
    },
    {
      path: '/camping/california/near/sacramento',
      label: 'Sacramento, CA',
      dataEvent: 'click_sacramento',
    },
    {
      path: '/camping/new-mexico/near/santa-fe',
      label: 'Santa Fe, NM',
      dataEvent: 'click_santa_fe',
    },
    {
      path: '/camping/utah/near/saint-george',
      label: 'Saint George, UT',
      dataEvent: 'click_saint_george',
    },
    {
      path: '/camping/missouri/near/st-louis',
      label: 'St Louis, MO',
      dataEvent: 'click_st_louis',
    },
    {
      path: '/camping/michigan/near/traverse-city',
      label: 'Traverse City, MN',
      dataEvent: 'click_',
    },
    {
      path: '/camping/virginia/near/virginia-beach',
      label: 'Virginia Beach, VA',
      dataEvent: 'click_virginia_beach',
    },
    {
      path: '/camping/district-of-columbia/near/washington-d-c',
      label: 'Washington D C',
      dataEvent: 'click_washington_d_c',
    },
    {
      path: '/camping/wisconsin/near/bayfield',
      label: 'Bayfield, WI',
      dataEvent: 'click_bayfield',
    },
    {
      path: '/camping/north-carolina/near/boone',
      label: 'Boone, NC',
      dataEvent: 'click_',
    },
    {
      path: '/camping/massachusetts/near/boston',
      label: 'Boston, MA',
      dataEvent: 'click_boston',
    },
    {
      path: '/camping/colorado/near/boulder',
      label: 'Boulder, CO',
      dataEvent: 'click_boulder',
    },
    {
      path: '/camping/colorado/near/buena-vista',
      label: 'Buena Vista, CO',
      dataEvent: 'click_buena_vista',
    },
    {
      path: '/camping/virginia/near/charlottesville',
      label: 'Charlottesville, VA',
      dataEvent: 'click_charlottesville',
    },
    {
      path: '/camping/wyoming/near/cody',
      label: 'Cody, WY',
      dataEvent: 'click_cody',
    },
    {
      path: '/camping/ohio/near/columbus',
      label: 'Columbus, OH',
      dataEvent: 'click_columbus',
    },
    {
      path: '/camping/colorado/near/crested-butte',
      label: 'Crested Butte, CO',
      dataEvent: 'click_crested_butte',
    },
    {
      path: '/camping/oregon/near/eugene',
      label: 'Eugene, OR',
      dataEvent: 'click_eugene',
    },
    {
      path: '/camping/california/near/eureka',
      label: 'Eureka, CA',
      dataEvent: 'click_eureka',
    },
    {
      path: '/camping/oregon/near/florence',
      label: 'Florence, OR',
      dataEvent: 'click_florence',
    },
    {
      path: '/camping/pennsylvania/near/gettysburg',
      label: 'Gettysburg, PA',
      dataEvent: 'click_gettysburg',
    },
    {
      path: '/camping/colorado/near/glenwood-springs',
      label: 'Glenwood Springs, CO',
      dataEvent: 'click_glenwood_springs',
    },
    {
      path: '/camping/missouri/near/kansas-city',
      label: 'Kansas City, MO',
      dataEvent: 'click_kansas_city',
    },
    {
      path: '/camping/michigan/near/mackinac-island',
      label: 'Mackinac Island, MI',
      dataEvent: 'click_',
    },
    {
      path: '/camping/wisconsin/near/madison',
      label: 'Madison, WI',
      dataEvent: 'click_madison',
    },
    {
      path: '/camping/tennessee/near/memphis',
      label: 'Memphis, TN',
      dataEvent: 'click_memphis',
    },
    {
      path: '/camping/california/near/monterey',
      label: 'Montery, CA',
      dataEvent: 'click_',
    },
    {
      path: '/camping/oregon/near/newport',
      label: 'Newport, OR',
      dataEvent: 'click_newport',
    },
    {
      path: '/camping/arizona/near/page',
      label: 'Page, AZ',
      dataEvent: 'click_page',
    },
    {
      path: '/camping/tennessee/near/pigeon-forge',
      label: 'Pigeon Forge, TN',
      dataEvent: 'click_pigeon_forge',
    },
    {
      path: '/camping/california/near/pismo-beach',
      label: 'Pismo Beach, CA',
      dataEvent: 'click_pismo_beach',
    },
    {
      path: '/camping/south-dakota/near/rapid-city',
      label: 'Rapid City, SD',
      dataEvent: 'click_rapid_city',
    },
    {
      path: '/camping/california/near/san-luis-obispo',
      label: 'San Luis Obispo, CA',
      dataEvent: 'click_san_luis_obispo',
    },
  ],
};

export const POPULAR_PARKS_CAMPING = {
  links: [
    {
      path: '/camping/wyoming/near/yellowstone-national-park',
      label: 'Yellowstone National Park',
      dataEvent: 'click_yellowstone_national_park',
    },
    {
      path: '/camping/california/near/yosemite-national-park',
      label: 'Yosemite National Park',
      dataEvent: 'click_yosemite_national_park',
    },
    {
      path: '/camping/arizona/near/grand-canyon-national-park',
      label: 'Grand Canyon National Park',
      dataEvent: 'click_grand_canyon_national_park',
    },
    {
      path: '/camping/utah/near/zion-national-park',
      label: 'Zion National Park',
      dataEvent: 'click_zion_national_park',
    },
    {
      path: '/camping/california/near/joshua-tree-national-park',
      label: 'Joshua Tree National Park',
      dataEvent: 'click_joshua_tree_national_park',
    },
    {
      path: '/camping/california/near/sequoia-and-kings-canyon-national-parks',
      label: 'Sequoia And Kings Canyon National Parks',
      dataEvent: 'click_sequoia_and_kings_canyon_national_parks',
    },
    {
      path: '/camping/maine/near/acadia-national-park',
      label: 'Acadia National Park',
      dataEvent: 'click_acadia_national_park',
    },
    {
      path: '/camping/montana/near/glacier-national-park',
      label: 'Glacier National Park',
      dataEvent: 'click_glacier_national_park',
    },
    {
      path: '/camping/wyoming/near/grand-teton-national-park',
      label: 'Grand Teton National Park',
      dataEvent: 'click_grand_teton_national_park',
    },
    {
      path: '/camping/north-carolina/near/great-smoky-mountains-national-park',
      label: 'Great Smoky Mountains National Park',
      dataEvent: 'click_great_smoky_mountains_national_park',
    },
    {
      path: '/camping/california/near/death-valley-national-park',
      label: 'Death Valley National Park',
      dataEvent: 'click_death_valley_national_park',
    },
    {
      path: '/camping/oregon/near/crater-lake-national-park',
      label: 'Crater Lake National Park',
      dataEvent: 'click_crater_lake_national_park',
    },
    {
      path: '/camping/virginia/near/shenandoah-national-park',
      label: 'Shenandoah National Park',
      dataEvent: 'click_shenandoah_national_park',
    },
    {
      path: '/camping/texas/near/big-bend-national-park',
      label: 'Big Bend National Park',
      dataEvent: 'click_big_bend_national_park',
    },
    {
      path: '/camping/utah/near/bryce-canyon-national-park',
      label: 'Bryce Canyon National Park',
      dataEvent: 'click_bryce_canyon_national_park',
    },
    {
      path: '/camping/california/near/redwood-national-park',
      label: 'Redwood National Park',
      dataEvent: 'click_redwood_national_park',
    },
    {
      path: '/camping/washington/near/mount-rainier-national-park',
      label: 'Mount Rainier National Park',
      dataEvent: 'click_mount_rainier_national_park',
    },
    {
      path: '/camping/washington/near/olympic-national-park',
      label: 'Olympic National Park',
      dataEvent: 'click_olympic_national_park',
    },
    {
      path: '/camping/utah/near/arches-national-park',
      label: 'Arches National Park',
      dataEvent: 'click_arches_national_park',
    },
    {
      path: '/camping/indiana/indiana-dunes-state-park',
      label: 'Indiana Dunes State Park',
      dataEvent: 'click_indiana_dunes_state_park',
    },
    {
      path: '/camping/colorado/near/rocky-mountain-national-park',
      label: 'Rocky Mountain National Park',
      dataEvent: 'click_rocky_mountain_national_park',
    },
    {
      path: '/camping/south-dakota/near/badlands-national-park',
      label: 'Badlands National Park',
      dataEvent: 'click_badlands_national_park',
    },
    {
      path: '/camping/kentucky/near/mammoth-cave-national-park',
      label: 'Mammoth Cave National Park',
      dataEvent: 'click_mammoth_cave_national_park',
    },
    {
      path: '/camping/california/near/lassen-volcanic-national-park',
      label: 'Lassen Volcanic National Park',
      dataEvent: 'click_lassen_volcanic_national_park',
    },
    {
      path: '/camping/utah/near/canyonlands-national-park',
      label: 'Canyonlands National Park',
      dataEvent: 'click_canyonlands_national_park',
    },
    {
      path: '/camping/new-mexico/near/white-sands',
      label: 'White Sands',
      dataEvent: 'click_white_sands',
    },
    {
      path: '/camping/west-virginia/near/new-river-gorge-national-river',
      label: 'New River Gorge National River',
      dataEvent: 'click_new_river_gorge_national_river',
    },
    {
      path: '/camping/colorado/near/great-sand-dunes-national-park-and-preserve',
      label: 'Great Sand Dunes National Park And Preserve',
      dataEvent: 'click_great_sand_dunes_national_park_and_preserve',
    },
    {
      path: '/camping/utah/near/capitol-reef-national-park',
      label: 'Capitol Reef National Park',
      dataEvent: 'click_capitol_reef_national_park',
    },
    {
      path: '/camping/colorado/near/mesa-verde-national-park',
      label: 'Mesa Verde National Park',
      dataEvent: 'click_mesa_verde_national_park',
    },
    {
      path: '/camping/california/near/pinnacles-national-park',
      label: 'Pinnacles National Park',
      dataEvent: 'click_pinnacles_national_park',
    },
    {
      path: '/camping/new-mexico/near/carlsbad-caverns',
      label: 'Carlsbad Caverns',
      dataEvent: 'click_carlsbad_caverns',
    },
    {
      path: '/camping/washington/near/north-cascades-national-park',
      label: 'North Cascades National Park',
      dataEvent: 'click_north_cascades_national_park',
    },
    {
      path: '/camping/nevada/near/great-basin-national-park',
      label: 'Great Basin National Park',
      dataEvent: 'click_great_basin_national_park',
    },
    {
      path: '/camping/arizona/near/saguaro-national-park',
      label: 'Saguaro National Park',
      dataEvent: 'click_saguaro_national_park',
    },
    {
      path: '/camping/california/near/channel-islands-national-park',
      label: 'Channel Islands National Park',
      dataEvent: 'click_channel_islands_national_park',
    },
    {
      path: '/camping/north-dakota/near/theodore-roosevelt-national-park',
      label: 'Theodore Roosevelt National Park',
      dataEvent: 'click_theodore_roosevelt_national_park',
    },
    {
      path: '/camping/arizona/near/petrified-forest-natl-pk',
      label: 'Petrified Forest Natl Pk',
      dataEvent: 'click_petrified_forest_natl_pk',
    },
    {
      path: '/camping/ohio/near/cuyahoga-valley-national-park',
      label: 'Cuyahoga Valley National Park',
      dataEvent: 'click_cuyahoga_valley_national_park',
    },
    {
      path: '/camping/alaska/near/denali-national-park',
      label: 'Denali National Park',
      dataEvent: 'click_denali_national_park',
    },
    {
      path: '/camping/texas/near/guadalupe-mountains-national-park',
      label: 'Guadalupe Mountains National Park',
      dataEvent: 'click_guadalupe_mountains_national_park',
    },
    {
      path: '/camping/florida/near/everglades-national-park',
      label: 'Everglades National Park',
      dataEvent: 'click_everglades_national_park',
    },
    {
      path: '/camping/colorado/near/black-canyon-of-the-gunnison-national-park',
      label: 'Black Canyon Of The Gunnison National Park',
      dataEvent: 'click_black_canyon_of_the_gunnison_national_park',
    },
    {
      path: '/camping/arkansas/near/hot-springs-national-park',
      label: 'Hot Springs National Park',
      dataEvent: 'click_hot_springs_national_park',
    },
    {
      path: '/camping/south-carolina/congaree-national-park',
      label: 'Congaree National Park',
      dataEvent: 'click_congaree_national_park',
    },
    {
      path: '/camping/minnesota/near/voyageurs-national-park',
      label: 'Voyageurs National Park',
      dataEvent: 'click_voyageurs_national_park',
    },
    {
      path: '/camping/michigan/near/isle-royale-national-park',
      label: 'Isle Royale National Park',
      dataEvent: 'click_isle_royale_national_park',
    },
    {
      path: '/camping/florida/near/biscayne-national-park',
      label: 'Biscayne National Park',
      dataEvent: 'click_biscayne_national_park',
    },
    {
      path: '/camping/south-dakota/near/wind-cave-national-park',
      label: 'Wind Cave National Park',
      dataEvent: 'click_wind_cave_national_park',
    },
    {
      path: '/camping/virgin-islands/near/virgin-islands-national-park',
      label: 'Virgin Islands National Park',
      dataEvent: 'click_virgin_islands_national_park',
    },
    {
      path: '/camping/alaska/near/kenai-fjords-national-park',
      label: 'Kenai Fjords National Park',
      dataEvent: 'click_kenai_fjords_national_park',
    },
    {
      path: '/camping/hawaii/near/haleakala-national-park',
      label: 'Haleakala National Park',
      dataEvent: 'click_haleakala_national_park',
    },
    {
      path: '/camping/alaska/near/glacier-bay-national-park-preserve',
      label: 'Glacier Bay National Park Preserve',
      dataEvent: 'click_rv',
    },
    {
      path: '/camping/alaska/near/katmai-national-park-preserve',
      label: 'Katmai National Park Preserve',
      dataEvent: 'click_katmai_national_park_preserve',
    },
    {
      path: '/camping/hawaii/near/hawaii-volcanoes-national-park',
      label: 'Hawaii Volcanoes National Park',
      dataEvent: 'click_hawaii_volcanoes_national_park',
    },
  ],
};

export const STATES_CAMPING = {
  links: [
    {
      path: '/camping/alabama',
      label: 'Alabama',
      dataEvent: 'click_alabama',
    },
    {
      path: '/camping/alaska',
      label: 'Alaska',
      dataEvent: 'click_alaska',
    },
    {
      path: '/camping/arizona',
      label: 'Arizona',
      dataEvent: 'click_arizona',
    },
    {
      path: '/camping/arkansas',
      label: 'Arkansas',
      dataEvent: 'click_arkansas',
    },
    {
      path: '/camping/california',
      label: 'California',
      dataEvent: 'click_california',
    },
    {
      path: '/camping/colorado',
      label: 'Colorado',
      dataEvent: 'click_colorado',
    },
    {
      path: '/camping/connecticut',
      label: 'Connecticut',
      dataEvent: 'click_connecticut',
    },
    {
      path: '/camping/delaware',
      label: 'Delaware',
      dataEvent: 'click_delaware',
    },
    {
      path: '/camping/florida',
      label: 'Florida',
      dataEvent: 'click_florida',
    },
    {
      path: '/camping/georgia',
      label: 'Georgia',
      dataEvent: 'click_georgia',
    },
    {
      path: '/camping/hawaii',
      label: 'Hawaii',
      dataEvent: 'click_hawaii',
    },
    {
      path: '/camping/idaho',
      label: 'Idaho',
      dataEvent: 'click_idaho',
    },
    {
      path: '/camping/illinois',
      label: 'Illinois',
      dataEvent: 'click_illinois',
    },
    {
      path: '/camping/indiana',
      label: 'Indiana',
      dataEvent: 'click_indiana',
    },
    {
      path: '/camping/iowa',
      label: 'Iowa',
      dataEvent: 'click_iowa',
    },
    {
      path: '/camping/kansas',
      label: 'Kansas',
      dataEvent: 'click_kansas',
    },
    {
      path: '/camping/kentucky',
      label: 'Kentucky',
      dataEvent: 'click_kentucky',
    },
    {
      path: '/camping/louisiana',
      label: 'Louisiana',
      dataEvent: 'click_louisiana',
    },
    {
      path: '/camping/maine',
      label: 'Maine',
      dataEvent: 'click_maine',
    },
    {
      path: '/camping/maryland',
      label: 'Maryland',
      dataEvent: 'click_maryland',
    },
    {
      path: '/camping/massachusetts',
      label: 'Massachusetts',
      dataEvent: 'click_massachusetts',
    },
    {
      path: '/camping/michigan',
      label: 'Michigan',
      dataEvent: 'click_michigan',
    },
    {
      path: '/camping/minnesota',
      label: 'Minnesota',
      dataEvent: 'click_minnesota',
    },
    {
      path: '/camping/mississippi',
      label: 'Mississippi',
      dataEvent: 'click_mississippi',
    },
    {
      path: '/camping/missouri',
      label: 'Missouri',
      dataEvent: 'click_missouri',
    },
    {
      path: '/camping/montana',
      label: 'Montana',
      dataEvent: 'click_montana',
    },
    {
      path: '/camping/nebraska',
      label: 'Nebraska',
      dataEvent: 'click_nebraska',
    },
    {
      path: '/camping/nevada',
      label: 'Nevada',
      dataEvent: 'click_nevada',
    },
    {
      path: '/camping/new-hampshire',
      label: 'New Hampshire',
      dataEvent: 'click_new_hampshire',
    },
    {
      path: '/camping/new-jersey',
      label: 'New Jersey',
      dataEvent: 'click_new_jersey',
    },
    {
      path: '/camping/new-mexico',
      label: 'New Mexico',
      dataEvent: 'click_new_mexico',
    },
    {
      path: '/camping/new-york',
      label: 'New York',
      dataEvent: 'click_new_york',
    },
    {
      path: '/camping/north-carolina',
      label: 'North Carolina',
      dataEvent: 'click_north_carolina',
    },
    {
      path: '/camping/north-dakota',
      label: 'North Dakota',
      dataEvent: 'click_north_dakota',
    },
    {
      path: '/camping/ohio',
      label: 'Ohio',
      dataEvent: 'click_ohio',
    },
    {
      path: '/camping/oklahoma',
      label: 'Oklahoma',
      dataEvent: 'click_oklahoma',
    },
    {
      path: '/camping/oregon',
      label: 'Oregon',
      dataEvent: 'click_oregon',
    },
    {
      path: '/camping/pennsylvania',
      label: 'Pennsylvania',
      dataEvent: 'click_pennsylvania',
    },
    {
      path: '/camping/puerto-rico',
      label: 'Puerto Rico',
      dataEvent: 'click_puerto_rico',
    },
    {
      path: '/camping/rhode-island',
      label: 'Rhode Island',
      dataEvent: 'click_rhode_island',
    },
    {
      path: '/camping/south-carolina',
      label: 'South Carolina',
      dataEvent: 'click_south_carolina',
    },
    {
      path: '/camping/south-dakota',
      label: 'South Dakota',
      dataEvent: 'click_south_dakota',
    },
    {
      path: '/camping/tennessee',
      label: 'Tennessee',
      dataEvent: 'click_tennessee',
    },
    {
      path: '/camping/texas',
      label: 'Texas',
      dataEvent: 'click_texas',
    },
    {
      path: '/camping/utah',
      label: 'Utah',
      dataEvent: 'click_utah',
    },
    {
      path: '/camping/vermont',
      label: 'Vermont',
      dataEvent: 'click_vermont',
    },
    {
      path: '/camping/virgin-islands',
      label: 'Virgin Islands',
      dataEvent: 'click_virgin_islands',
    },
    {
      path: '/camping/virginia',
      label: 'Virginia',
      dataEvent: 'click_virginia',
    },
    {
      path: '/camping/washington',
      label: 'Washington',
      dataEvent: 'click_washington',
    },
    {
      path: '/camping/west-virginia',
      label: 'West Virginia',
      dataEvent: 'click_west_virginia',
    },
    {
      path: '/camping/wisconsin',
      label: 'Wisconsin',
      dataEvent: 'click_wisconsin',
    },
    {
      path: '/camping/wyoming',
      label: 'Wyoming',
      dataEvent: 'click_wyoming',
    },
  ],
};
