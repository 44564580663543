import React from 'react';

import styles from './styles/AppFooter.module.scss';

export interface _IntroTextProps {
  className?: string;
}

export const _IntroText: React.FC<_IntroTextProps> = ({
  className,
}: _IntroTextProps) => {
  return (
    <div className={`${styles['footer__border-section']} ${className}`}>
      <h4 className={styles['footer__title']}>
        The Dyrt is the #1 camping platform with the most campgrounds, reviews,
        and tips.
      </h4>
      <p className={styles['footer__text']}>
        Book campgrounds for tents, RVs, cabins, and glamping, or find free
        camping. The Dyrt has it all.
      </p>
    </div>
  );
};

export default _IntroText;
