import React, { useEffect } from 'react';
import { LocalBusinessJsonLd, LogoJsonLd, NextSeo } from 'next-seo';
import { SiteLinksSearchBoxJsonLd } from 'next-seo';
import { MetaTag } from 'next-seo/lib/types';
import { useRouter } from 'next/router';
import classNames from 'classnames/bind';
import isEmpty from 'lodash.isempty';

import isMobileDevice from 'utils/isMobileDevice';
import { isCTAHidden } from 'utils/localStorage';

import useCleanRoute from 'hooks/useCleanRoute';
import { useOverlayContext } from 'hooks/useOverlayContext';

import AppFooter, { AppFooterHide } from 'components/AppFooter/AppFooter';
import AppHeader, { AppHeaderHide } from 'components/AppHeader/AppHeader';

import styles from './styles/AppPage.module.scss';

const FB_APP_ID = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
const HOST = process.env.NEXT_PUBLIC_EMBER_HOST;
const PUBLIC = process.env.NEXT_PUBLIC_ASSETS;

const cx = classNames.bind(styles);

const defaultSocialImage = `${PUBLIC}/assets/images/thedyrt-og-1200x630.jpg`;

export interface AppPageProps {
  content: JSX.Element;
  pageTitle: string;
  socialDescription?: string;
  socialImage?: string;
  noPadding?: boolean;
  centerContent?: boolean;
  contentBG?: string;
  noFrame?: boolean;
  hideHeader?: AppHeaderHide;
  hideFooter?: AppFooterHide;
  disableSearch?: boolean;
  noIndex?: boolean;
  headerClassName?: string;
  crumbs?: JSX.Element;
  canonicalUrl?: string;
  noCanonical?: boolean;
  additionalMetaTags?: ReadonlyArray<MetaTag>;
  customHeader?: JSX.Element | null;
  customFooter?: JSX.Element | null;
  adPlacement?: JSX.Element | null;
  banner?: JSX.Element | null;
}

export const AppPage: React.FC<AppPageProps> = ({
  content,
  pageTitle,
  socialDescription,
  socialImage = defaultSocialImage,
  noPadding,
  centerContent,
  contentBG,
  noFrame,
  hideHeader,
  disableSearch,
  hideFooter,
  noIndex,
  headerClassName,
  crumbs,
  canonicalUrl,
  noCanonical,
  additionalMetaTags,
  customHeader,
  customFooter,
  banner,
}: AppPageProps) => {
  const router = useRouter();
  const isMobile = isMobileDevice();

  const cleanRoute = useCleanRoute();

  const { overlays, setOverlay } = useOverlayContext();

  const hasOverlay = !isEmpty(overlays);

  const showWebToAppCtaForPage =
    router.asPath.includes('/claim') ||
    router.asPath.includes('/checkout') ||
    router.asPath.includes('/signin') ||
    router.asPath.includes('/signup')
      ? false
      : true;

  useEffect(() => {
    const agent = window.navigator.userAgent;
    let timeoutId: number;

    hasOverlay
      ? document.body.classList.add('overlay-open')
      : document.body.classList.remove('overlay-open');

    if (
      !hasOverlay &&
      !isCTAHidden('hide-see-in-app-until') &&
      isMobile &&
      showWebToAppCtaForPage &&
      !agent.toLowerCase().includes('googlebot')
    ) {
      timeoutId = window.setTimeout(() => {
        setOverlay({ type: 'see-in-app' });
      }, 3000) as number;
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [hasOverlay, isMobile, setOverlay, showWebToAppCtaForPage]);

  const mainClasses = cx({
    main: true,
    'main--no-frame': noFrame,
  });

  const interiorClasses = cx({
    main__interior: true,
    'main__interior--no-padding': noPadding,
    'main__interior--is-centered': centerContent,
    'main__interior--no-frame': noFrame,
  });

  return (
    <>
      <LogoJsonLd
        keyOverride="DyrtLogo"
        logo="https://www.thedyrt.com/assets/brand/horizontal/logo-color_brown.svg"
        url="https://www.thedyrt.com"
      />
      <LocalBusinessJsonLd
        keyOverride="DyrtOrganization"
        type="Organization"
        id={`${HOST}#organization`}
        url={`${HOST}`}
        name="The Dyrt"
        description="Find and book camping near me from over 12,000,000 locations, tips & photos submitted by campers like you."
        address={{
          streetAddress: '',
          addressLocality: '',
          addressRegion: '',
          postalCode: '',
          addressCountry: 'US',
        }}
      />
      <SiteLinksSearchBoxJsonLd
        url="https://www.thedyrt.com"
        potentialActions={[
          {
            target: 'https://thedyrt.com/search?q',
            queryInput: 'search_term_string',
          },
        ]}
      />
      <NextSeo
        title={pageTitle}
        description={socialDescription}
        additionalMetaTags={additionalMetaTags}
        openGraph={{
          url: `${HOST}${router.asPath}`,
          title: pageTitle,
          type: 'website',
          description: socialDescription,
          images: [{ url: socialImage }],
          site_name: 'The Dyrt',
          locale: 'en_US',
        }}
        twitter={{
          site: '@TheDyrt',
        }}
        facebook={{
          appId: String(FB_APP_ID),
        }}
        canonical={
          canonicalUrl && !noCanonical
            ? canonicalUrl
            : !noCanonical
            ? `${HOST}${cleanRoute}`
            : undefined
        }
        noindex={noIndex}
      />
      <div className={styles.page}>
        {customHeader || (
          <AppHeader
            hide={hideHeader}
            disableSearch={disableSearch}
            className={headerClassName}
          />
        )}
        {banner}
        <main
          className={mainClasses}
          style={{ backgroundColor: contentBG || 'inherit' }}
        >
          {crumbs}

          <div className={interiorClasses}>{content}</div>
        </main>
        {customFooter || <AppFooter hide={hideFooter} />}
      </div>
    </>
  );
};

export default AppPage;
