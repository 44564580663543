import React from 'react';
import Image from 'next/image';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppAppStore from 'components/AppAppStore/AppAppStore';
import AppPlayStore from 'components/AppPlayStore/AppPlayStore';
import AppRatings from 'components/AppRatings/AppRatings';

import styles from './styles/AppFooter.module.scss';

export interface _GetAppLinksProps {
  className?: string;
}

const GetAppLinks: React.FC<_GetAppLinksProps> = ({
  className,
}: _GetAppLinksProps) => {
  return (
    <div className={`${styles['get-app-links']} ${className}`}>
      <div className={styles['get-app-links__copy']}>
        <h4 className={styles['footer__title']}>
          Got the top ranked camping app?
        </h4>
        <p className={styles['footer__text']}>Download The Dyrt app now</p>
      </div>
      <div className={styles['get-app-links__links']}>
        <AppAppStore className={styles['get-app-links__link']} />
        <AppPlayStore className={styles['get-app-links__link']} />
      </div>
      <div className={styles['get-app-links__ratings']}>
        <Image
          src={`${PUBLIC_ASSETS_PATH}/assets/images/AppStoreBadge_Dark@3x.png`}
          alt="Apple App Store Featured App"
          width={101}
          height={41}
        />
        <AppRatings />
      </div>
    </div>
  );
};

export default GetAppLinks;
