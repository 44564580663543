import React from 'react';

import getSearchLinkFromAutocomplete from 'utils/getSearchLinkFromAutocomplete';
import { getIcon } from 'utils/search';

import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';
import AppPhoto from 'components/AppPhoto/AppPhoto';

import styles from './styles/AppSearch.module.scss';

export interface _ListItemProps {
  result: UnifiedAutocomplete;
  resultElementRef: React.RefObject<HTMLAnchorElement[]>;
  onResultSelected?: (
    result: UnifiedAutocomplete,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  index: number;
  changeActiveIndex: (
    e: React.KeyboardEvent<HTMLAnchorElement | HTMLInputElement>
  ) => void;
  data_event: string;
}

export const _ListItem: React.FC<_ListItemProps> = ({
  result,
  resultElementRef,
  onResultSelected,
  index,
  changeActiveIndex,
  data_event,
}) => {
  return (
    <li className={styles['results__item']} key={index}>
      <AppLink href={getSearchLinkFromAutocomplete(result)}>
        <a
          className={styles['results__link']}
          ref={(element) => {
            if (element && resultElementRef?.current) {
              resultElementRef.current[index] = element;
            }
          }}
          data-event={data_event}
          onClick={(e) => {
            onResultSelected && onResultSelected(result, e);
          }}
          onKeyUp={changeActiveIndex}
        >
          {result.photoUrl ? (
            <AppPhoto
              className={styles['results__image']}
              src={result.photoUrl}
              alt="Campground image"
              options={{
                width: 48,
                height: 48,
                fit: 'crop',
                crop: 'smart',
              }}
              lazyLoad={false}
              width="48"
              height="48"
            />
          ) : (
            <AppIcon
              classNameWrapper={styles['results__icon-container']}
              classNameSvg={[`${styles['results__icon']}`]}
              icon={getIcon(result)}
            />
          )}

          <div className={styles['results__item-labels']}>
            <span className={styles['results__item-name']}>{result.name}</span>
            {!(result.type === 'place' && result.placeType === 'state') && (
              <span className={styles['results__item-state']}>
                {result.regionName}
              </span>
            )}
          </div>
        </a>
      </AppLink>
    </li>
  );
};

export default _ListItem;
