import React from 'react';

import { EXPLORE, MEET_THE_DYRT } from 'constants/links';

import LinkList from './_LinkList';
import IntroText from './_IntroText';

import styles from './styles/AppFooter.module.scss';

export interface _NavProps {
  className?: string;
}

export const Nav: React.FC<_NavProps> = ({ className }: _NavProps) => {
  return (
    <nav className={`${styles.nav} ${className}`}>
      <div className={styles['nav__column']}>
        <LinkList title={EXPLORE.title} items={EXPLORE.links} />
      </div>
      <div className={styles['nav__column']}>
        <LinkList title={MEET_THE_DYRT.title} items={MEET_THE_DYRT.links} />
      </div>
      <IntroText className={styles['nav__description']} />
    </nav>
  );
};

export default Nav;
