import React, { useRef, useEffect } from 'react';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import * as amplitude from '@amplitude/analytics-browser';

import {
  CLAIM_CTA_CLICK,
  PRO_CTA_CLICK,
  DYRT_ALERTS_CTA_CLICK,
} from 'constants/amplitude';
import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import { useUser } from 'contexts/currentUser';

import getNavLink from 'utils/getNavLink';

import useAmplitudePageName from 'hooks/useAmplitudePageName';

import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';

import { PARKS, CITIES, STATES, FORESTS } from './assets/nav';

import styles from './styles/AppHeader.module.scss';

const NavList: React.FC = () => {
  const { user } = useUser();
  const pathname = usePathname();
  const userIsPro = user?.pro;

  const navGroup = useRef<HTMLLIElement[]>([]);

  const pageName = useAmplitudePageName();

  const createDyrtAlertPath =
    userIsPro && user.remainingProScans < user.totalProScans
      ? '/alerts/my-alerts'
      : '/alerts';

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', navGroupClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', navGroupClick);
    };
  }, []);

  const navGroupClick = (e: MouseEvent) => {
    navGroup.current.forEach((navGroup: HTMLLIElement) => {
      if (e.target instanceof HTMLElement && !navGroup.contains(e.target)) {
        navGroup.classList.remove(styles['link-group--is-open']);
      }
    });
  };

  const toggleNavGroup = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    const target = e.target;
    if (target instanceof HTMLElement || target instanceof SVGElement) {
      navGroup.current.forEach((navGroup) => {
        if (
          navGroup.contains(target) &&
          !navGroup.classList.contains(styles['link-group--is-open'])
        ) {
          navGroup.classList.add(styles['link-group--is-open']);
        } else {
          navGroup.classList.remove(styles['link-group--is-open']);
        }
      });
    }
  };

  const onButtonClick = () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
      amplitude.track(CLAIM_CTA_CLICK, {
        'button name': 'Header - List Your Campground',
        'page name': pageName,
      });
    }
  };

  const onUpsellClick = () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
      amplitude.track(PRO_CTA_CLICK, {
        'button name': 'Nav Menu Try Pro',
        'page name': pageName,
      });
    }
  };

  const onProMapsClick = () => {
    if (process.env.NEXT_PUBLIC_AMPLITUDE_KEY && !userIsPro) {
      amplitude.track(PRO_CTA_CLICK, {
        'button name': 'Unlock PRO Benefits',
        'page name': pageName,
      });
    }
  };

  return (
    <nav className={styles.nav}>
      <ul className={styles['nav__list']}>
        <li
          className={`${styles['nav__item']}`}
          ref={(e) => {
            if (e) {
              navGroup.current[0] = e;
            }
          }}
          onClick={toggleNavGroup}
        >
          <span
            className={styles['link-group__title']}
            data-event="click_nav_toggle_group"
          >
            Find Camping
            <AppIcon
              classNameSvg={[`${styles['link-group__chevron']}`]}
              icon="chevron_down"
            />
          </span>
          <div className={styles['link-group__list']}>
            <h4>
              <AppLink href={getNavLink('/search')} prefetch={false}>
                <a className={styles['link-group__search-link']}>
                  Explore the Map
                  <AppIcon
                    classNameSvg={[`${styles['link-group__chevron']}`]}
                    icon="chevron_left"
                  />
                </a>
              </AppLink>
            </h4>
            <ul className={styles['link-group__places-group']}>
              <li className={styles['link-group__item']}>
                <h4 className={styles['link-group__places-title']}>
                  Most Searched Parks
                </h4>
                {PARKS.map((link) => (
                  <AppLink href={getNavLink(link.path, user)} key={link.label}>
                    <a
                      className={styles['link-group__link']}
                      data-event={link.dataEvent}
                    >
                      {link.label}
                    </a>
                  </AppLink>
                ))}
              </li>
              <li className={styles['link-group__item']}>
                <h4 className={styles['link-group__places-title']}>
                  Most Searched Cities
                </h4>
                {CITIES.map((link) => (
                  <AppLink href={getNavLink(link.path, user)} key={link.label}>
                    <a
                      className={styles['link-group__link']}
                      data-event={link.dataEvent}
                    >
                      {link.label}
                    </a>
                  </AppLink>
                ))}
              </li>
              <li className={styles['link-group__item']}>
                <h4 className={styles['link-group__places-title']}>
                  Most Searched States
                </h4>
                {STATES.map((link) => (
                  <AppLink href={getNavLink(link.path, user)} key={link.label}>
                    <a
                      className={styles['link-group__link']}
                      data-event={link.dataEvent}
                    >
                      {link.label}
                    </a>
                  </AppLink>
                ))}
              </li>
              <li className={styles['link-group__item']}>
                <h4 className={styles['link-group__places-title']}>
                  Most Searched Forests
                </h4>
                {FORESTS.map((link) => (
                  <AppLink href={getNavLink(link.path, user)} key={link.label}>
                    <a
                      className={styles['link-group__link']}
                      data-event={link.dataEvent}
                    >
                      {link.label}
                    </a>
                  </AppLink>
                ))}
              </li>
            </ul>
          </div>
        </li>
        <li
          className={`${styles['nav__item']} ${
            pathname === '/alerts' ? styles['link-group--is-open'] : ''
          }`}
          ref={(e) => {
            if (e) {
              navGroup.current[1] = e;
            }
          }}
          onClick={toggleNavGroup}
        >
          <span className={styles['link-group__title']}>
            <AppLink href={createDyrtAlertPath}>
              <a
                className={styles['link-group__nav-link']}
                onClick={() => {
                  amplitude.track(DYRT_ALERTS_CTA_CLICK, {
                    'page name': pageName,
                    'button name': 'Header - Create a Dyrt Alert',
                  });
                }}
                data-event="click_nav_create_a_dyrt_alert"
              >
                Create a Dyrt Alert
                <AppIcon
                  classNameSvg={[`${styles['link-group__chevron']}`]}
                  icon="chevron_right"
                />
              </a>
            </AppLink>
          </span>
        </li>
        <li
          className={`${styles['nav__item']} ${
            pathname === '/pro' ? styles['link-group--is-open'] : ''
          }`}
          ref={(e) => {
            if (e) {
              navGroup.current[2] = e;
            }
          }}
          onClick={toggleNavGroup}
        >
          <span className={styles['link-group__title']}>
            <AppLink href={getNavLink('/pro')}>
              <a
                className={styles['link-group__nav-link']}
                onClick={onProMapsClick}
                data-event="click_nav_toggle_group"
                data-google-interstitial="false"
              >
                {userIsPro ? 'My PRO Benefits' : 'Unlock PRO Benefits'}
                <AppIcon
                  classNameSvg={[`${styles['link-group__chevron']}`]}
                  icon="chevron_right"
                />
              </a>
            </AppLink>
          </span>
        </li>
        <li
          className={`${styles['nav__item']} ${
            pathname?.startsWith('/claim') ? styles['link-group--is-open'] : ''
          }`}
          ref={(e) => {
            if (e) {
              navGroup.current[4] = e;
            }
          }}
          onClick={toggleNavGroup}
        >
          <span className={styles['link-group__title']}>
            <AppLink href={getNavLink('/claim')}>
              <a
                className={styles['link-group__nav-link']}
                onClick={onButtonClick}
                data-event="click_nav_toggle_group"
                data-google-interstitial="false"
              >
                List Your Property
                <AppIcon
                  classNameSvg={[`${styles['link-group__chevron']}`]}
                  icon="chevron_right"
                />
              </a>
            </AppLink>
          </span>
        </li>
      </ul>

      {user && !user.pro && !user.manager && (
        <AppLink href="/pro/checkout">
          <a
            className={styles['nav__banner']}
            data-event="click_nav_free"
            onClick={onUpsellClick}
            data-google-interstitial="false"
          >
            {user?.name.split(' ').shift()}, try{' '}
            <Image
              className={styles[`nav__banner-pro-badge`]}
              src={`${PUBLIC_ASSETS_PATH}/assets/badges/pro.svg`}
              alt={`The Dyrt Pro`}
              width={33}
              height={16}
            />{' '}
            membership for free
          </a>
        </AppLink>
      )}
    </nav>
  );
};

export default NavList;
