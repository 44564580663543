import React from 'react';
import Image from 'next/image';
import classNames from 'classnames/bind';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import AppLink from 'components/AppLink/AppLink';

import Nav from './_Nav';
import Legal from './_Legal';
import SocialLinks from './_SocialLinks';
import GetAppLinks from './_GetAppLinks';

import styles from './styles/AppFooter.module.scss';

const cx = classNames.bind(styles);

export type AppFooterHide = 'mobile' | 'desktop' | boolean;

export interface AppFooterProps {
  hide?: AppFooterHide;
}
export const AppFooter: React.FC<AppFooterProps> = ({
  hide,
}: AppFooterProps) => {
  const footerClassNames = cx({
    footer: true,
    'footer--hide': hide === true,
    'footer--hide-mobile': hide === 'mobile',
    'footer--hide-desktop': hide === 'desktop',
  });

  return (
    <footer className={footerClassNames}>
      <div className={styles['footer__interior']}>
        <div className={styles['footer__logo']}>
          <AppLink href={`/`}>
            <a
              className={styles['logo-link']}
              data-event="Consumer_Footer_Dyrt-Logo"
            >
              <Image
                className={styles['footer__logo-mark']}
                src={`${PUBLIC_ASSETS_PATH}/assets/brand/horizontal/logo-color_brown.svg`}
                alt="The Dyrt – find campgrounds for tents, RVs, cabins, glamping and more."
                width={145}
                height={45}
              />
            </a>
          </AppLink>
        </div>
        <SocialLinks className={styles['footer__social']} />
        <GetAppLinks className={styles['footer__app-links']} />
        <Nav className={styles['footer__nav']} />
        <Legal className={styles['footer__legal']} />
      </div>
    </footer>
  );
};

export default AppFooter;
