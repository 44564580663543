import { useEffect, useState } from 'react';

import isMobileDevice from 'utils/isMobileDevice';

const useIsMobileDevice = () => {
  const [mobileDevice, setMobileDevice] = useState(false);

  useEffect(() => {
    setMobileDevice(isMobileDevice());
  }, []);

  return mobileDevice;
};

export default useIsMobileDevice;
