import React from 'react';
import Image from 'next/image';

import { PUBLIC_ASSETS_PATH } from 'constants/paths';

import styles from './styles/AppAppStore.module.scss';

export interface AppAppStoreProps {
  className?: string;
}

export const AppAppStore: React.FC<AppAppStoreProps> = ({
  className,
}: AppAppStoreProps) => {
  return (
    <a
      href="https://apps.apple.com/us/app/the-dyrt-tent-rv-camping/id1187626329"
      className={`${className} ${styles['apple']}`}
      target="_blank"
      rel="noreferrer"
    >
      <Image
        src={`${PUBLIC_ASSETS_PATH}/assets/images/app-store@2x.png`}
        alt="App Store"
        data-event="click_footer_ios_app"
        className={styles['apple__image']}
        width={125}
        height={42}
      />
    </a>
  );
};

export default AppAppStore;
