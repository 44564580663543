interface State {
  open: boolean;
  value: string;
  activeIndex: number;
  recommended: CampgroundAutocomplete[];
  results: UnifiedAutocomplete[];
  completed: boolean;
}

type Action =
  | {
      type: 'SET_RESULTS';
      payload: UnifiedAutocomplete[];
    }
  | {
      type: 'SET_RECOMMENDED';
      payload: CampgroundAutocomplete[];
    }
  | {
      type: 'SET_OPEN';
      payload: boolean;
    }
  | {
      type: 'SET_ACTIVE_INDEX';
      payload: number;
    }
  | {
      type: 'SEARCH_INPUT_CHANGE';
      payload: string;
    }
  | {
      type: 'ON_RESULT_SELECTED';
      payload: string;
    };

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_RESULTS':
      return { ...state, results: action.payload, completed: true };
    case 'SET_RECOMMENDED':
      return { ...state, recommended: action.payload };
    case 'SET_OPEN':
      return { ...state, open: action.payload };
    case 'SET_ACTIVE_INDEX':
      return { ...state, activeIndex: action.payload };
    case 'SEARCH_INPUT_CHANGE': {
      return {
        ...state,
        open: true,
        activeIndex: -1,
        value: action.payload,
        completed: false,
      };
    }
    case 'ON_RESULT_SELECTED': {
      return { ...state, open: false, value: action.payload };
    }

    default:
      return state;
  }
};
