import React from 'react';

import { useUser } from 'contexts/currentUser';

import getNavLink from 'utils/getNavLink';

import AppAvatar from 'components/AppAvatar/AppAvatar';
import AppIcon from 'components/AppIcon/AppIcon';
import AppLink from 'components/AppLink/AppLink';

import styles from './styles/AppHeader.module.scss';

const HOST = process.env.NEXT_PUBLIC_EMBER_HOST;
export interface NavDrawerUserMenuProps {
  className?: string;
}

const NavDrawerUserMenu: React.FC<NavDrawerUserMenuProps> = ({
  className,
}: NavDrawerUserMenuProps) => {
  const { user } = useUser();

  if (!user) return null;
  const showManagerPortalLink = user.admin || user.manager;

  return (
    <div className={`${styles['drawer-logged-in']} ${className}`}>
      <div className={styles['drawer-logged-in__avatar-container']}>
        <AppAvatar
          className={styles['drawer-logged-in__avatar']}
          user={user}
          hideInfo
          size="sm"
          linkToUser
        />
        <span className={styles['drawer-logged-in__user-name']}>
          {user.name}
        </span>
      </div>
      <div className={styles['drawer-logged-in__actions']}>
        {showManagerPortalLink && (
          <AppLink href={getNavLink('/manager', user)}>
            <a
              className={styles['drawer-logged-in__action']}
              data-event="click_nav_manager"
            >
              <AppIcon
                classNameWrapper={
                  styles['drawer-logged-in__action-icon-wrapper']
                }
                classNameSvg={[`${styles['drawer-logged-in__action-icon']}`]}
                icon="calendar"
              />
              Manager Portal
            </a>
          </AppLink>
        )}
        <AppLink href={`/alerts/my-alerts`}>
          <a
            className={styles['drawer-logged-in__action']}
            data-event="click_dryt_alerts"
          >
            <AppIcon
              classNameWrapper={
                styles['drawer-logged-in__action-icon-wrapper--alerts']
              }
              classNameSvg={[`${styles['drawer-logged-in__action-icon']}`]}
              icon="alerts"
            />
            My Dyrt Alerts
          </a>
        </AppLink>
        <AppLink href={`${HOST}/member/${user?.slug}/lists`} external>
          <a
            className={styles['drawer-logged-in__action']}
            data-event="click_nav_lists"
          >
            <AppIcon
              classNameWrapper={styles['drawer-logged-in__action-icon-wrapper']}
              classNameSvg={[`${styles['drawer-logged-in__action-icon']}`]}
              category="menu"
              icon="lists"
            />
            My Lists
          </a>
        </AppLink>
        <AppLink href={`${HOST}/trips`}>
          <a
            className={styles['drawer-logged-in__action']}
            data-event="click_nav_trips"
          >
            <AppIcon
              classNameWrapper={styles['drawer-logged-in__action-icon-wrapper']}
              classNameSvg={[`${styles['drawer-logged-in__action-icon']}`]}
              category="menu"
              icon="trip"
            />
            My Trips
          </a>
        </AppLink>
      </div>
    </div>
  );
};

export default NavDrawerUserMenu;
