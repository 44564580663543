export const profileItems = [
  {
    label: 'My Dyrt Alerts',
    path: '/alerts/my-alerts',
    icon: 'alerts',
    dataEvent: 'click_nav_my_alerts',
  },
  {
    label: 'My Lists',
    path: ':host/member/:slug/lists',
    icon: 'heart_outlined',
    dataEvent: 'click_nav_my_lists',
    external: true, // needed so that we open the ember page not the wip next.js page
  },
  {
    label: 'Route Planner',
    path: ':host/trips',
    icon: 'trip',
    category: 'menu',
    dataEvent: 'click_nav_route_planner',
  },
  {
    label: 'Account',
    path: ':host/member/:slug/settings',
    icon: 'profile',
    category: 'menu',
    dataEvent: 'click_profile',
    external: true, // needed so that we open the ember page not the wip next.js page
  },
];

export default profileItems;
