export const MORE = [
  {
    label: 'Review a Campground',
    path: ':host/find-to-review',
    dataEvent: 'click_nav_drawer_find_to_review',
  },
  {
    label: 'Add a Location',
    path: '/add',
    dataEvent: 'click_nav_drawer_add_campground',
  },
  {
    label: 'Gift Cards',
    path: '/pro/gift',
    dataEvent: 'click_nav_drawer_gift_cards',
  },
  {
    label: 'Contests',
    path: ':host/contests',
    dataEvent: 'click_nav_drawer_contests',
  },
  {
    label: 'Magazine',
    path: 'https://thedyrt.com/magazine/',
    dataEvent: 'click_nav_drawer_magazine',
  },
  {
    label: 'Advertise',
    path: 'https://join.thedyrt.com/advertising',
    dataEvent: 'click_nav_drawer_advertise',
  },
  {
    label: 'About Us',
    path: '/about',
    dataEvent: 'click_nav_drawer_about_us',
  },
  {
    label: 'Press',
    path: 'https://press.thedyrt.com/',
    dataEvent: 'click_nav_drawer_press',
  },
  {
    label: 'Careers',
    path: 'https://the-dyrt.breezy.hr/',
    dataEvent: 'click_nav_drawer_careers',
  },
  {
    label: 'Support',
    path: 'https://support.thedyrt.com/hc/en-us',
    dataEvent: 'click_nav_drawer_support',
  },
];
