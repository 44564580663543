import React from 'react';

import AppNavDrawer from 'components/AppNavDrawer/AppNavDrawer';

import _NavDrawerContent from './_NavDrawerContent';

interface Props {
  navClassName?: string;
  toggleLight?: boolean;
}

const AppHeaderNavDrawer: React.FC<Props> = ({ navClassName, toggleLight }) => {
  return (
    <AppNavDrawer
      navClassName={navClassName}
      toggleLight={toggleLight}
      renderContent={(isNavOpen) => <_NavDrawerContent isOpen={isNavOpen} />}
    />
  );
};

export default AppHeaderNavDrawer;
