import * as amplitude from '@amplitude/analytics-browser';

import useAmplitudePageName from 'hooks/useAmplitudePageName';

export const useLogAmplitudeOnNavClick = () => {
  const pageName = useAmplitudePageName();

  return (link: LinkType) => {
    if (link.eventName && process.env.NEXT_PUBLIC_AMPLITUDE_KEY) {
      amplitude.track(link.eventName, {
        'button name': link.eventButtonName,
        'page name': pageName,
      });
    }
  };
};
