import React from 'react';

import AppIcon from 'components/AppIcon/AppIcon';

import styles from './styles/AppRatings.module.scss';

type Props = {
  rating?: number | null;
  text?: string | null;
  starClassName?: string;
};

export const AppRatings: React.FC<Props> = ({
  rating = 4.8,
  text = '80k ratings',
  starClassName = '',
}) => {
  const starClassNames = `${styles['ratings__star']} ${starClassName}`;

  return (
    <span className={styles['ratings']}>
      {rating && <span className={starClassNames}>{rating}</span>}
      <AppIcon icon="star" classNameWrapper={starClassNames} />
      <AppIcon icon="star" classNameWrapper={starClassNames} />
      <AppIcon icon="star" classNameWrapper={starClassNames} />
      <AppIcon icon="star" classNameWrapper={starClassNames} />
      <AppIcon icon="star" classNameWrapper={starClassNames} />
      {text && <span className={styles['ratings__text']}>{text}</span>}
    </span>
  );
};

export default AppRatings;
